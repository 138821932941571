import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HomeIcon from '@mui/icons-material/Home';
import BreadCrumb from ".";

const HomeCrumb: FC = ()  => {
  const history = useHistory();
  const {t} = useTranslation();

  return (
    <div style={{margin: "2rem"}}>
      <BreadCrumb
        click={() => history.push("/")}
        crumbIcon={<HomeIcon fontSize="small"/>}
        crumbText={`${t("crumbText.home")}`}
      />
    </div>
  )};

export default HomeCrumb;
