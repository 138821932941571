import * as React from "react";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import { ButtonProps } from "@mui/material";

interface Props extends ButtonProps {
  isLoading: boolean;
}

const LoadingButton: React.FC<Props> = (
  {
    isLoading,
    variant = "contained",
    color = "primary",
    type = "submit",
    children,
    ...other
  }) => (
  <MuiLoadingButton
    variant={variant}
    color={color}
    type={type}
    loading={isLoading}
    loadingPosition="start"
    startIcon={<div/>}
    sx={{ px: 5 }}
    {...other}
  >
    {children}
  </MuiLoadingButton>
);

export { LoadingButton };
