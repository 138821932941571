import * as React from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Field, useFormikContext } from "formik";
import { Dayjs } from "dayjs";
import { TextField } from "formik-mui";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  variant?: "outlined" | "filled" | "standard";
  validationRegex?: string;
  validationMessage?: string;
}

const DatePickerField: React.FC<Props> = (
  {
    name, label, required, disabled, helperText,
    variant, validationRegex, validationMessage
  }) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<Record<string, Dayjs>>();
  const onChange = React.useCallback((dayjs: Dayjs | null) => {
    setFieldValue(name, dayjs?.format("MM/DD/YYYY"));
  }, []);
  const validate = React.useCallback((value: Dayjs | null) => {
    if (required && !value) {
      return t("common.validation.required");
    }
    if (validationRegex && value && !value.format("MM/DD/YYYY").match(new RegExp(validationRegex))) {
      return validationMessage || "Invalid format";
    }
    return undefined;
  }, [required, validationRegex, validationMessage]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={values[name] || null}
        onChange={onChange}
        disabled={disabled}
        renderInput={({ error, ...other }) => {
          if (error) {
            window.console.log("Ignoring component's error value", error);
          }
          return (
            <Field
              {...other}
              name={name}
              component={TextField}
              validate={validate}
              required={required}
              helperText={helperText}
              variant={variant || "standard"}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export { DatePickerField };
