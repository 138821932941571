import * as React from "react";
import { useMutation, useQuery } from "react-query";
import {
  Address,
  ClientConfig,
  ClientConfigsService,
  CustomFieldDefinitionCategory,
  CustomFieldValue,
  UserAccountsService,
  UserGroup
} from "gen/clients/llts";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserInfo, UserInfoInput } from "./components/UserInfoInput/UserInfoInput";
import { CompanySelection } from "./components/CompanySelection/CompanySelection";
import { CustomFieldValuesInput } from "./components/CustomFieldValuesInput/CustomFieldValuesInput";
import { AccountCreated } from "./components/AccountCreated/AccountCreated";
import { SignUpError } from "./components/SignUpError/SignUpError";
import { UserAddressInput } from "./components/UserAddressInput/UserAddressInput";

enum SignUpPhase {
  USER_INFO_INPUT = "USER_INFO_INPUT",
  USER_ADDRESS_INPUT = "USER_ADDRESS_INPUT",
  COMPANY_NOT_FOUND = "COMPANY_NOT_FOUND",
  INVALID_CLIENT_ID = "INVALID_CLIENT_ID",
  INVALID_TOKEN = "INVALID_TOKEN",
  COMPANY_SELECTION = "COMPANY-SELECTION",
  CUSTOM_FIELD_VALUES_INPUT = "CUSTOM_FIELD_VALUES_INPUT",
  ACCOUNT_CREATED = "ACCOUNT_CREATED"
}

interface RouterParams {
  clientId?: string;
  token?: string;
}

const SignUpPage: React.FC = () => {
  const { t } = useTranslation();
  const { clientId: encodedClientId, token } = useParams<RouterParams>();
  const [signUpPhase, setSignUpPhase] = React.useState(SignUpPhase.USER_INFO_INPUT);
  const [userInfo, setUserInfo] = React.useState<UserInfo>();
  const [userAddress, setUserAddress] = React.useState<Address>();
  const [clientConfig, setClientConfig] = React.useState<ClientConfig>();
  const [customFieldValues, setCustomFieldValues] = React.useState<CustomFieldValue[]>();
  const decodedClientId = encodedClientId ? atob(decodeURIComponent(encodedClientId)) : undefined;

  const { data: providedClientConfig } = useQuery(["getClientConfig", decodedClientId], {
    enabled: !!decodedClientId,
    queryFn: () => ClientConfigsService.getClientConfig({ clientId: decodedClientId || "" })
  });

  const { data: clientConfigs, isFetching: clientConfigsFetching, error: clientConfigsError } =
    useQuery(["listClientConfigs", userInfo?.email], {
      enabled: !!userInfo?.email,
      queryFn: () => ClientConfigsService.listClientConfigs(
        { emailDomain: userInfo?.email.substring(userInfo?.email.indexOf("@") + 1) || "" })
    });

  const {
    data: customFieldDefinitions,
    isFetching: customFieldDefinitionsFetching,
    error: customFieldDefinitionsError
  } =
    useQuery(["getCustomFieldDefinitions", clientConfig?.clientId], {
      enabled: !!clientConfig?.clientId,
      queryFn: () => ClientConfigsService.getCustomFieldDefinitions({
        clientId: clientConfig?.clientId || "",
        category: CustomFieldDefinitionCategory.USER
      })
    });

  const {
    mutate: createUserAccount,
    error: createUserAccountError,
    isSuccess: createUserAccountSuccessful,
    isLoading: createUserAccountLoading
  } =
    useMutation("createUserAccount", {
      mutationFn: UserAccountsService.creatUserAccount,
    });

  const isLoading = clientConfigsFetching || customFieldDefinitionsFetching || createUserAccountLoading;
  const apiError = clientConfigsError || customFieldDefinitionsError || createUserAccountError;

  const onCancel = React.useCallback(() => {
    setUserInfo(undefined);
    setUserAddress(undefined);
    setClientConfig(undefined);
    setCustomFieldValues(undefined);
    setSignUpPhase(SignUpPhase.USER_INFO_INPUT);
  }, []);

  React.useEffect(() => {
    if (!clientConfigs) {
      return;
    }
    if (!userAddress) {
      setSignUpPhase(SignUpPhase.USER_ADDRESS_INPUT);
      return;
    }
    if (clientConfigs.length === 0) {
      setSignUpPhase(SignUpPhase.COMPANY_NOT_FOUND);
    }
    if (clientConfigs.length === 1) {
      const config = clientConfigs[0];
      if (decodedClientId && decodedClientId !== config.clientId) {
        setSignUpPhase(SignUpPhase.INVALID_CLIENT_ID);
      } else if (decodedClientId && token !== config.signUpToken) {
        setSignUpPhase(SignUpPhase.INVALID_TOKEN);
      } else {
        setClientConfig(config);
      }
    }
    if (clientConfigs.length > 1) {
      if (decodedClientId) {
        const config = clientConfigs.find(c => c.clientId === decodedClientId);
        if (!config) {
          setSignUpPhase(SignUpPhase.INVALID_CLIENT_ID);
        } else if (token !== config.signUpToken) {
          setSignUpPhase(SignUpPhase.INVALID_TOKEN);
        } else {
          setClientConfig(config);
        }
      } else {
        setSignUpPhase(SignUpPhase.COMPANY_SELECTION);
      }
    }
  }, [clientConfigs, userAddress]);

  React.useEffect(() => {
    if (!customFieldDefinitions) {
      return;
    }
    if (customFieldDefinitions.length === 0) {
      setCustomFieldValues([]);
    } else if (customFieldDefinitions.length > 0) {
      setSignUpPhase(SignUpPhase.CUSTOM_FIELD_VALUES_INPUT);
    }
  }, [customFieldDefinitions]);

  React.useEffect(() => {
    if (createUserAccountSuccessful) {
      setSignUpPhase(SignUpPhase.ACCOUNT_CREATED);
    }
  }, [createUserAccountSuccessful]);

  React.useEffect(() => {
    if (!userInfo || !userAddress || !clientConfig || !customFieldValues) {
      return;
    }
    createUserAccount({
      requestBody: {
        clientId: clientConfig.clientId,
        email: userInfo.email,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        password: userInfo.password,
        role: UserGroup.CLIENT_EMPLOYEE,
        address: userAddress,
        customFieldValues
      }
    });
  }, [userInfo, userAddress, clientConfig, customFieldValues]);

  switch (signUpPhase) {
    case SignUpPhase.USER_INFO_INPUT:
      return <UserInfoInput
        logoUrl={providedClientConfig?.logoUrl}
        onSuccess={setUserInfo}
        isLoading={isLoading}
        error={apiError}
      />;
      case SignUpPhase.USER_ADDRESS_INPUT:
        return <UserAddressInput
          logoUrl={providedClientConfig?.logoUrl}
          onSuccess={setUserAddress}
          onCancel={onCancel}
          isLoading={isLoading}
          error={apiError}
        />;
      case SignUpPhase.COMPANY_SELECTION:
      return <CompanySelection
        clientConfigs={clientConfigs || []}
        onSelection={setClientConfig}
        isLoading={isLoading}
        error={apiError}
        onCancel={onCancel}
      />;
    case SignUpPhase.CUSTOM_FIELD_VALUES_INPUT:
      return <CustomFieldValuesInput
        customFieldDefinitions={customFieldDefinitions || []}
        onValuesEntry={setCustomFieldValues}
        logoUrl={providedClientConfig?.logoUrl}
        isLoading={isLoading}
        error={apiError}
        onCancel={onCancel}
      />;
    case SignUpPhase.ACCOUNT_CREATED:
      return <AccountCreated logoUrl={providedClientConfig?.logoUrl}/>;
    case SignUpPhase.COMPANY_NOT_FOUND:
      return <SignUpError
        message={providedClientConfig ? t("signUp.invalidClientId") : t("signUp.companyNotFound")}
        logoUrl={providedClientConfig?.logoUrl} onCancel={onCancel}
      />;
    case SignUpPhase.INVALID_CLIENT_ID:
      return <SignUpError
        message={t("signUp.invalidClientId")}
        logoUrl={providedClientConfig?.logoUrl} onCancel={onCancel}
      />;
    case SignUpPhase.INVALID_TOKEN:
      return <SignUpError
        message={t("signUp.invalidToken")}
        logoUrl={providedClientConfig?.logoUrl} onCancel={onCancel}
      />;
    default:
      throw new Error(`Unknown SignUpPhase [${signUpPhase}]`);
  }
};

export { SignUpPage };
