import * as React from "react";
import { useQuery } from "react-query";
import { Province, UtilsService } from "gen/clients/llts";
import { SelectOption } from "@mui/base";

interface Response {
  provinces?: Province[];
  provinceOptions: SelectOption<number>[];
  isProvincesLoading: boolean;
  provincesError?: unknown;
}

export const useProvinceOptions = (countryId: number | undefined): Response => {

  const { data: provinces, isLoading: isProvincesLoading, error: provincesError } = useQuery("listProvinces", {
    queryFn: UtilsService.listProvinces
  })
  const provinceOptions = React.useMemo(() =>
    provinces?.filter(p => p.countryId === countryId)
      .sort((l1, l2) => l1.name.localeCompare(l2.name))
      .map(l => ({ label: l.name, value: l.id })) || [], [provinces, countryId]);
  return {
    provinces,
    provinceOptions,
    isProvincesLoading,
    provincesError
  }
}