import { Action } from "redux";
import { UserGroup } from "../../gen/clients/llts";

export interface UserSessionState {
  username?: string;
  email?: string;
  roles?: UserGroup[];
  isFederatedSsoAccount?: boolean;
  firstName?: string | null;
  lastName?: string | null;
  initialPagePath?: string;
}

export enum UserSessionActionType {
  SIGN_IN = "USER_SESSION/SIGN_IN",
  SIGN_OUT = "USER_SESSION/SIGN_OUT",
  SET_INITIAL_PAGE_PATH = "USER_SESSION/SET_INITIAL_PAGE_PATH"
}

export interface SignInAction extends Action {
  type: UserSessionActionType.SIGN_IN;
  username: string;
  email: string;
  roles: UserGroup[];
  isFederatedSsoAccount?: boolean;
  firstName?: string | null;
  lastName?: string | null;
}

export interface SignOutAction extends Action {
  type: UserSessionActionType.SIGN_OUT;
}

export interface SetInitialPagePathAction extends Action {
  type: UserSessionActionType.SET_INITIAL_PAGE_PATH;
  initialPagePath: string;
}

export type UserSessionActions = SignInAction | SignOutAction | SetInitialPagePathAction;
