/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateJobRequestPayload } from '../models/CreateJobRequestPayload';
import type { GetJobRequestFileDownloadUrlResponse } from '../models/GetJobRequestFileDownloadUrlResponse';
import type { JobRequest } from '../models/JobRequest';
import type { JobRequestCollection } from '../models/JobRequestCollection';
import type { RenameJobRequestPayload } from '../models/RenameJobRequestPayload';
import type { SubmitJobRequestToXtrfPayload } from '../models/SubmitJobRequestToXtrfPayload';
import type { ZipJobRequestTranslatedFilesStatusResponse } from '../models/ZipJobRequestTranslatedFilesStatusResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class JobRequestsService {

    /**
     * Lists job requests for an authenticated current user
     * @returns JobRequestCollection Successful response
     * @throws ApiError
     */
    public static listJobRequests({
        origination,
        pageToken,
        pageSize,
    }: {
        /** Origination of queried job requests **/
        origination: any,
        /** Page token **/
        pageToken?: any,
        /** Page size **/
        pageSize?: any,
    }): CancelablePromise<JobRequestCollection> {
        return __request({
            method: 'GET',
            path: `/jobRequests`,
            query: {
                'origination': origination,
                'pageToken': pageToken,
                'pageSize': pageSize,
            },
            errors: {
                403: `Forbidden`,
                404: `Not found`,
            },
        });
    }

    /**
     * Creates a new job request
     * @returns JobRequest Successfully created job request
     * @throws ApiError
     */
    public static createJobRequest({
        requestBody,
    }: {
        /** Payload for creating a job request **/
        requestBody?: CreateJobRequestPayload,
    }): CancelablePromise<JobRequest> {
        return __request({
            method: 'POST',
            path: `/jobRequests`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Fetches a job request
     * @returns JobRequest Successful response
     * @throws ApiError
     */
    public static getJobRequest({
        jobRequestId,
    }: {
        /** Job request ID **/
        jobRequestId: any,
    }): CancelablePromise<JobRequest> {
        return __request({
            method: 'GET',
            path: `/jobRequests/${jobRequestId}`,
            errors: {
                403: `Forbidden`,
                404: `Not found`,
            },
        });
    }

    /**
     * Submits job request to XTRF
     * @returns any Successfuly accepted request
     * @throws ApiError
     */
    public static submitToXtrf({
        jobRequestId,
        requestBody,
    }: {
        /** Job request ID **/
        jobRequestId: any,
        /** Payload for submitting a job request to XTRF **/
        requestBody?: SubmitJobRequestToXtrfPayload,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/jobRequests/${jobRequestId}/actions/submitToXtrf`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                404: `Not found`,
            },
        });
    }

    /**
     * Renames job request
     * @returns any Successful operation
     * @throws ApiError
     */
    public static renameJobRequest({
        jobRequestId,
        requestBody,
    }: {
        /** Job request ID **/
        jobRequestId: any,
        /** Payload for renaming job request **/
        requestBody?: RenameJobRequestPayload,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/jobRequests/${jobRequestId}/actions/renameJobRequest`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                404: `Not found`,
            },
        });
    }

    /**
     * Return temporary download url of job request file
     * @returns GetJobRequestFileDownloadUrlResponse successful operation
     * @throws ApiError
     */
    public static getJobRequestFileDownloadUrl({
        jobRequestId,
        fileKey,
    }: {
        jobRequestId: string,
        fileKey: string,
    }): CancelablePromise<GetJobRequestFileDownloadUrlResponse> {
        return __request({
            method: 'GET',
            path: `/jobRequests/${jobRequestId}/files/${fileKey}/downloadUrl`,
            errors: {
                400: `Invalid request`,
                404: `Not found`,
            },
        });
    }

    /**
     * Zip job request translated files
     * @returns void
     * @throws ApiError
     */
    public static zipJobRequestTranslatedFiles({
        jobRequestId,
    }: {
        /** Job request ID **/
        jobRequestId: any,
    }): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/jobRequests/${jobRequestId}/actions/zipTranslatedFiles`,
            errors: {
                403: `Forbidden`,
                404: `Not found`,
            },
        });
    }

    /**
     * Get status of job request translated files zipping operation
     * @returns ZipJobRequestTranslatedFilesStatusResponse Successful operation
     * @throws ApiError
     */
    public static getZipTranslatedFilesStatus({
        jobRequestId,
    }: {
        /** Job request ID **/
        jobRequestId: any,
    }): CancelablePromise<ZipJobRequestTranslatedFilesStatusResponse> {
        return __request({
            method: 'GET',
            path: `/jobRequests/${jobRequestId}/actions/zipTranslatedFiles/status`,
            errors: {
                403: `Forbidden`,
                404: `Not found`,
            },
        });
    }

}