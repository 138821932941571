import * as React from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  CardHeader,
  Box
} from "@mui/material";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import IDashBoardDetailPage from "interfaces/dashboardPage";
import { StatisticsService } from "../../gen/clients/llts";
import HomeCrumb from "../../components/Breadcrumb/HomeCrumb";
import HeaderRow from "./components/HeaderRow/HeaderRow";

const DetailsPage: React.FC<IDashBoardDetailPage> = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const pageId = props.id;
  const { resourceKey } = props;
  const { id } = useParams<{ id: string }>();
  const { name } = useParams<{ name: string }>();
  const { t } = useTranslation();

  const isPastDue = pageId === "pastDueDetails";

  const {
    data: theStats,
    isLoading,
    isError
  } = useQuery([pageId, id], {
    queryFn: () =>
      isPastDue
        ? StatisticsService.getPastDueStatisticsById({ id })
        : StatisticsService.getDueTodayStatisticsById({ id })
  });

  if (isError) {
    return <h2>{t("common.systemError")}</h2>;
  }

  /* If past due column exists */
  const insertPastDue = (pastDue: string) => (
    <>
      <TableCell>{pastDue}</TableCell>
    </>
  );

  const generateRows = () =>
    theStats?.map((row) => (
      <TableRow
        key={row.projectId}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 }
        }}
      >
        <TableCell component="th" scope="row">
          <a
            href={`${process.env.REACT_APP_XTRF_HOME}/xtrf/faces/project/edit.seam?action=edit&id=${row.projectId}`}
            target="_blank"
            rel="noreferrer"
          >
            {row.internalProjectId}
          </a>
        </TableCell>
        {isPastDue && insertPastDue(row.pastDue)}
        <TableCell>{row.projectDeadline?.slice(0, 10)}</TableCell>
        <TableCell>{row.status}</TableCell>
        <TableCell>{row.projectName}</TableCell>
        <TableCell>{row.client}</TableCell>
        <TableCell>{row.languages}</TableCell>
        <TableCell>{row.totalAgreed}</TableCell>
        <TableCell>{row.rateType}</TableCell>
        <TableCell>{row.projectGroupName}</TableCell>
        <TableCell>{row.projectGroupId}</TableCell>
      </TableRow>
    ));

  const title = isPastDue
    ? t("DASHBOARD.pastDueQuotes", { name })
    : t("DASHBOARD.dueTodayQuotes", { name });

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <HomeCrumb />
      </div>
      <Box sx={{ pt: 3 }}>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid>
              <Card sx={{ backgroundColor: "#f5f5f5" }}>
                <CardHeader title={title} />
                <CardContent>
                  {isLoading && <CircularProgress />}
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <HeaderRow resourceKey={`${resourceKey}`} />
                      </TableRow>
                    </TableHead>
                    <TableBody>{generateRows()}</TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default DetailsPage;
