import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import {
  applyMiddleware, combineReducers, createStore, Store,
} from 'redux';
import thunk from 'redux-thunk';
import { History } from 'history';
import { UserSessionState } from './userSession/types';
import userSessionReducer from './userSession/userSessionReducers';

export interface ApplicationState {
  userSession: UserSessionState;
  router: RouterState;
}

export const configureStore = (history: History): Store<ApplicationState> => {
  const rootReducer = combineReducers<ApplicationState>({
    router: connectRouter(history),
    userSession: userSessionReducer
  });

  return createStore(rootReducer, undefined, applyMiddleware(thunk, routerMiddleware(history)));
};
