import * as React from "react";
import { useQuery } from "react-query";
import {
  ApiError,
  ClientConfigsService,
  CustomFieldDefinition,
  CustomFieldDefinitionCategory,
  CustomFieldValue,
  FileCategory,
  FileLink,
  ProjectSubmissionMode,
  UserAccountsService
} from "gen/clients/llts";
import { Alert, Box, Container, CssBaseline, Grid } from "@mui/material";
import { Formik } from "formik";
import { FormikProps } from "formik/dist/types";
import { SelectOption } from "@mui/base";
import { useTranslation } from "react-i18next";
import { sanitizeFieldName } from "components/formikFields/formikUtils";
import {
  ContentInputMethod,
  CreateProjectForm,
  FieldNames,
  SubmitType
} from "../components/CreateProjectForm/CreateProjectForm";
import { LoadingPage } from "../../../components/LoadingPage/LoadingPage";
import { ErrorPage } from "../../../components/ErrorPage/ErrorPage";
import { FormHeader } from "../components/FormHeader/FormHeader";
import { useCreateProject } from "../hooks/useCreateProject";
import { SnackbarApiError } from "../../../components/SnackbarApiError/SnackbarApiError";
import { RequestSubmitted } from "../components/RequestSubmitted/RequestSubmitted";
import { CreateProjectFormValidator } from "../components/CreateProjectForm/CreateProjectFormValidator";
import { createProjectName } from "../utils/projectUtils";
import { ProjectInfoFieldName } from "../components/ProjectInfoSection/ProjectInfoSection";
import { FilesSectionFieldNames } from "../components/FilesSection/FilesSection";

interface Props {
  email: string
}

type Values = Record<string, unknown>;

const SignedInUserPage: React.FC<Props> = ({ email }) => {
  const { t } = useTranslation();
  const [requestSubmitted, setRequestSubmitted] = React.useState(false);
  const [submitType, setSubmitType] = React.useState<SubmitType>(SubmitType.PROJECT);
  const formikRef = React.useRef<FormikProps<Values>>(null);
  const { createProject, createQuote, isInProgress, createProjectError, createQuoteError, submissionProgress } = useCreateProject({
    formRef: formikRef,
    onSuccess: () => setRequestSubmitted(true)
  });

  React.useEffect(() => {
    const form = formikRef.current;
    if (form) {
      form.setSubmitting(isInProgress);
    }
  }, [isInProgress]);

  const { data: userAccount, isLoading: isUserAccountLoading, error: userAccountError } =
    useQuery(["getUserAccount", email], {
      queryFn: UserAccountsService.getUserAccount
    });
  const clientId = userAccount?.clientConfig?.clientId;
  const {
    data: projectCustomFieldDefinitions,
    isLoading: areCustomFieldDefinitionsLoading,
    error: customFieldDefinitionsError
  } =
    useQuery(["listCustomFieldDefinitions", clientId, CustomFieldDefinitionCategory.PROJECT], {
      enabled: !!clientId,
      queryFn: () => ClientConfigsService.getCustomFieldDefinitions(
        { clientId: clientId || "", category: CustomFieldDefinitionCategory.PROJECT }
      )
    })

  const isLoading = isUserAccountLoading || areCustomFieldDefinitionsLoading;
  const error = userAccountError || customFieldDefinitionsError;

  const initialValues = React.useMemo(() => {
    const standardFieldValues = {
      [ProjectInfoFieldName.sourceLanguage]: null,
      [ProjectInfoFieldName.targetLanguages]: [],
      [ProjectInfoFieldName.notes]: "",
      [ProjectInfoFieldName.xtrfServiceId]: "",
      [FilesSectionFieldNames.inputMethod]: ContentInputMethod.FILES,
      [FilesSectionFieldNames.files]: "",
      [FilesSectionFieldNames.textInput]: "",
      [FilesSectionFieldNames.includeReferenceFiles]: [],
      [FilesSectionFieldNames.referenceFiles]: "",
      [FilesSectionFieldNames.fileLinks]: [],
      [ProjectInfoFieldName.projectName]: createProjectName(email),
      [FieldNames.submitType]: SubmitType.PROJECT
    };
    const customFieldValues: Record<string, string | string[]> = {};
    projectCustomFieldDefinitions?.forEach((customFieldDefinition: CustomFieldDefinition) => {
      customFieldValues[sanitizeFieldName(customFieldDefinition.name)] = customFieldDefinition.defaultValue || "";
    })
    return {
      ...standardFieldValues,
      ...customFieldValues
    }
  }, [projectCustomFieldDefinitions]);

  const validate = React.useCallback((values: Record<string, unknown>) =>
    CreateProjectFormValidator.validate(values, t), []);

  const onSubmit = React.useCallback((formValues) => {
    if (!clientId) {
      throw new Error("clientId is not set");
    }
    const customFieldValues: CustomFieldValue[] | undefined = projectCustomFieldDefinitions?.map(fieldDefinition => ({
      fieldName: fieldDefinition.name,
      mapping: fieldDefinition.mapping,
      value: formValues[sanitizeFieldName(fieldDefinition.name)]
    }));
    const requestBody = {
      clientId,
      projectName: formValues[ProjectInfoFieldName.projectName],
      contactInfo: {
        email
      },
      notes: formValues[ProjectInfoFieldName.notes],
      sourceLanguageId: formValues[ProjectInfoFieldName.sourceLanguage].value,
      targetLanguageIds: formValues[ProjectInfoFieldName.targetLanguages].map((option: SelectOption<number>) => option.value),
      xtrfServiceId: formValues[ProjectInfoFieldName.xtrfServiceId] ?
        +formValues[ProjectInfoFieldName.xtrfServiceId] : undefined,
      customFields: customFieldValues,
      fileLinks: formValues[FilesSectionFieldNames.inputMethod] === ContentInputMethod.FILELINKS ?
        (formValues[FilesSectionFieldNames.fileLinks] as FileLink[]).map(link => ({
          ...link,
          category: FileCategory.SOURCE_DOCUMENT
        })) : 
        undefined
    };

    if (formValues[FieldNames.submitType] === SubmitType.ESTIMATE) {
      setSubmitType(SubmitType.ESTIMATE)
      createQuote({ requestBody });
    } else {
      setSubmitType(SubmitType.PROJECT)
      createProject({ requestBody });
    }
  }, [projectCustomFieldDefinitions]);

  if (isLoading) {
    return <LoadingPage/>;
  }
  if (error) {
    return <ErrorPage apiError={error as ApiError}/>;
  }
  if (userAccount?.clientConfig?.status !== "ACTIVE") {
    return (
      <Container maxWidth="md">
        <Alert severity="error" sx={{ mt: 3 }}>
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: t("createProject.fromExternalLink.inactiveClient") }}/>
        </Alert>
      </Container>
    );
  }
  if (requestSubmitted) {
    return <RequestSubmitted
      submitType={submitType}
      bannerUrl={userAccount?.clientConfig?.bannerUrl}
    />;
  }

  return (
    <>
      <CssBaseline/>
      <FormHeader
        title={t("createProject.signedInUser.header.title")}
        bannerUrl={userAccount?.clientConfig?.bannerUrl}
      >
        <p>
          {t("createProject.signedInUser.header.content1")}
        </p>
        {/* eslint-disable-next-line react/no-danger */}
        <p dangerouslySetInnerHTML={{ __html: t("createProject.signedInUser.header.content2")}}/>
      </FormHeader>
      <Container maxWidth="md">
        {userAccount?.clientConfig?.instructionalText &&
          <Box sx={{ mb: 2 }}>
            <Alert severity="info">
              {userAccount?.clientConfig?.instructionalText.split("\n").map(line => <p key={line}>{line}</p>)}
            </Alert>
          </Box>
        }
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {userAccount && userAccount.clientConfig &&
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validate={validate}
                validateOnMount={false}
                innerRef={formikRef}
              >
                <CreateProjectForm
                  clientId={userAccount.clientConfig.clientId}
                  projectCustomFieldDefinitions={projectCustomFieldDefinitions}
                  workflowOptions={userAccount.clientConfig.selectableWorkflows}
                  hideContactSection={true}
                  documentInfoTitle={t("createProject.signedInUser.documentInfoTitle")}
                  projectInfoTitle={t("createProject.signedInUser.projectInfoTitle")}
                  hideSubmitForEstimation={userAccount.clientConfig.projectSubmissionMode === ProjectSubmissionMode.SUBMIT_FOR_TRANSLATION}
                  hideSubmitForTranslation={userAccount.clientConfig.projectSubmissionMode === ProjectSubmissionMode.SUBMIT_FOR_ESTIMATION}
                  footerMessage={userAccount.clientConfig.projectSubmissionFooterMessage}
                  submissionProgress={submissionProgress}
                />
              </Formik>
            }
          </Grid>
        </Grid>
        {createProjectError &&
          <SnackbarApiError error={createProjectError}/>
        }
        {createQuoteError &&
          <SnackbarApiError error={createQuoteError}/>
        }
      </Container>
    </>
  );
};

export { SignedInUserPage };
