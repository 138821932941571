import * as React from "react";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";

interface Props {
  message: string;
  logoUrl?: string;
  onCancel: () => void;
}

const SignUpError: React.FC<Props> = ({ message, logoUrl, onCancel }) => {
  const { t } = useTranslation();
  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline/>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {logoUrl && <img src={logoUrl} alt={t("signUp.logo.alt")}/>}
        {!logoUrl &&
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon/>
          </Avatar>
        }
        <Typography component="h1" variant="h5">
          {t("signUp.signUp")}
        </Typography>
        <Typography variant="h6" sx={{ my: 5 }}>
          {message}
        </Typography>
      </Box>
      <Box sx={{ mt: 5, mb: 2, textAlign: "center" }}>
        <Button
          variant="contained"
          onClick={onCancel}
        >
          {t("common.return")}
        </Button>
      </Box>

    </Container>
  );
};

export { SignUpError };
