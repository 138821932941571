import { SetInitialPagePathAction, SignInAction, SignOutAction, UserSessionActionType } from "./types";
import { UserGroup } from "../../gen/clients/llts";

export const signIn = (
  username: string,
  email: string,
  roles: UserGroup[],
  isFederatedSsoAccount?: boolean,
  firstName?: string | null,
  lastName?: string | null

): SignInAction => ({
  username,
  email,
  roles,
  isFederatedSsoAccount,
  firstName,
  lastName,
  type: UserSessionActionType.SIGN_IN
});

export const signOut = (): SignOutAction => ({
  type: UserSessionActionType.SIGN_OUT
});

export const setInitialPagePath = (initialPagePath: string): SetInitialPagePathAction => ({
  initialPagePath,
  type: UserSessionActionType.SET_INITIAL_PAGE_PATH
});
