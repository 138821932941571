import * as csvWriter from "csv-writer";
import { ReportColumn, ReportMetadata, ReportRowCollection } from "gen/clients/llts";
import { Exporter } from "./ExportTypes";

// Export classes
export class CSVExporter implements Exporter {
  exportData(report: ReportMetadata, reportData: ReportRowCollection) : { blob: Blob; filename: string; } {
    const csvData = this.convertToCSV(report.columns, reportData);
    const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    const filename = `${report.name}-${new Date().getTime()}.csv`;

    return { blob: csvBlob, filename };
  }

  /* eslint-disable-next-line  class-methods-use-this */
  private convertToCSV(columns: ReportColumn[], reportData: ReportRowCollection): string {
    const csvHeader = columns.map(c => (
      {
        id: c.columnId,
        title: c.name
      }
    ));

    const writer = csvWriter.createObjectCsvStringifier({
      header: csvHeader,
      alwaysQuote: true
    });

    const header = writer.getHeaderString();
    const records = writer.stringifyRecords(reportData.items);
    return `${header || ""}${records}`;
  }
}
