import React, { FC, useCallback } from "react";
import { Auth, CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import styles from "./AppHeader.module.scss";
import UserMenu from "./components/UserMenu/UserMenu";
import { useUserSession } from "../../hooks/useUserSession";
import { SessionExpirationManager } from "../SessionExpirationManager/SessionExpirationManager";

const AppHeader: FC = () => {
  const { username, email, isFederatedSsoAccount } = useUserSession();

  const signIn = useCallback(() => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Cognito });
  }, []);

  const { t } = useTranslation();

  return (
    <div className={styles.headerDiv}>
      {username && <SessionExpirationManager/>}
      <header>
        <div className={styles.logo}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <img src={t("configuration.logoUrl.image")} alt={t("configuration.logoUrl.altText")}/>
        </div>
        <div className={styles.loginService}>
          {!username && (
            <span>
              <span id="sign-in-button-span">
                <Button id="sign-in-button" onClick={signIn} variant="contained">
                  {t("appHeader.signIn")}
                </Button>
                <span>{t("appHeader.or")}</span>
              </span>
              <Link to="/signUp" style={{ color: "rgb(0, 87, 160)" }}>{t("appHeader.signUp")}</Link>
            </span>
          )}
          {username &&
            <UserMenu username={isFederatedSsoAccount ? email || username : username}/>
          }
        </div>
      </header>
    </div>
  );
};

export default AppHeader;
