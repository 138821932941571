import * as React from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Box
} from "@mui/material";

import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { formatIso } from "utils";
import { StatisticsService } from "../../gen/clients/llts";
import HomeCrumb from "../../components/Breadcrumb/HomeCrumb";
import IDashBoardDetailPage from "../../interfaces/dashboardPage";
import HeaderRow from "./components/HeaderRow/HeaderRow";

const QaSpecialistDetailsPage: React.FC<IDashBoardDetailPage> = (props) => {
  const {resourceKey} = props;
  const { id } = useParams<{ id: string }>();
  const { name } = useParams<{ name: string }>();
  const {t} = useTranslation();

  const {
    data: qaSpecialistStats,
    isLoading,
    isError
  } = useQuery(["qaSpecialistStats", id], {
    queryFn: () => StatisticsService.getQaSpecialistStatisticsById({ id })
  });

  if (isError) {
    return <h2>{t("common.systemError")}</h2>;
  }

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <HomeCrumb />
      </div>
      <Box sx={{ pt: 3 }}>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid>
              <Card sx={{ backgroundColor: "#f5f5f5" }}>
                <CardHeader title={t("DASHBOARD.projects", {name})} />
                <CardContent>
                  {isLoading && <CircularProgress />}
                  {qaSpecialistStats && (
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <HeaderRow resourceKey={resourceKey} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {qaSpecialistStats.map((row) => (
                          <TableRow
                            key={row.projectJobId}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 }
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <a
                                href={`${process.env.REACT_APP_XTRF_HOME}/xtrf/faces/project/edit.seam?action=edit&id=${row.projectId}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {row.projectNumber}
                              </a>
                            </TableCell>
                            <TableCell>{row.assignedTo}</TableCell>
                            <TableCell>
                              <a
                                href={`${process.env.REACT_APP_XTRF_HOME}/vendors/#/jobs/${row.jobAssignmentId}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {row.projectJobId}
                              </a>
                            </TableCell>
                            <TableCell>{formatIso(row.jobStartDate)}</TableCell>
                            <TableCell>{row.projectManager}</TableCell>
                            <TableCell>{row.languages}</TableCell>
                            <TableCell>{row.client}</TableCell>
                            <TableCell>
                              {formatIso(row.projectDeadline)}
                            </TableCell>
                            <TableCell>{row.jobStatus}</TableCell>
                            <TableCell>{row.qaType}</TableCell>
                            <TableCell>{row.projectGroupName}</TableCell>
                            <TableCell>{row.projectGroupId}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default QaSpecialistDetailsPage;
