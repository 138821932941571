import * as React from "react";
import { JobRequest, JobRequestsService } from "gen/clients/llts";
import { useMutation } from "react-query";
import { InlineEditableTextField } from "components/InlineEditableTextField/InlineEditableTextField";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";

interface Props {
  jobRequest: JobRequest;
  onChange: () => void;
}

const JobRequestNameEditable: React.FC<Props> = ({ jobRequest, onChange }) => {

  const { mutate, error } = useMutation(JobRequestsService.renameJobRequest, {onSuccess: onChange, onError: onChange});
  const onSave = (value: string) => {
    mutate({
      jobRequestId: jobRequest.id,
      requestBody: {
        newName: value
      }
    });
  }

  return (
    <>
      <InlineEditableTextField
        initialValue={jobRequest.name || ""}
        onSave={onSave}
        fontSize={20}
      />
      {error && <SnackbarApiError error={error}/>}
    </>
  );
};

export { JobRequestNameEditable };
