import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { useUserSessionOverride } from "hooks/useUserSessionOverride";
import { LoadingButton } from "../../../LoadingButton/LoadingButton";
import { TextInputField } from "../../../formikFields/TextInputField/TextInputField";
import { signIn } from "../../../../store/userSession/userSessionActions";
import { UserAccountsService } from "../../../../gen/clients/llts";
import { ApiErrorMessage } from "../../../ApiErrorMessage/ApiErrorMessage";

interface Props {
  onClose: () => void;
  onSuccess: () => void;
}

const ImpersonateUserDialog: React.FC<Props> = ({ onClose, onSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [userSessionOverride, setUserSessionOverride, clearUserSessionOverride] = useUserSessionOverride();

  const { data: overrideUserAccount, isLoading, error } = useQuery("getImpersonatedUser", {
    enabled: !!userSessionOverride,
    queryFn: UserAccountsService.getUserAccount,
    cacheTime: 0
  });

  const onDialogClose = React.useCallback((event, reason) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  }, [onClose]);

  const onSubmit = React.useCallback(async (values) => {
    const usernameOverride: string = values.usernameOverride;
    setUserSessionOverride({ username: usernameOverride });
  }, []);

  React.useEffect(() => {
    if (!overrideUserAccount) {
      return;
    }
    dispatch(signIn(overrideUserAccount.loginName, overrideUserAccount.email, overrideUserAccount.roles, undefined, undefined, undefined));
    setUserSessionOverride({
      username: overrideUserAccount.loginName,
      email: overrideUserAccount.email,
      roles: overrideUserAccount.roles
    });
    onSuccess();
  }, [overrideUserAccount]);

  React.useEffect(() => {
    if (error) {
      clearUserSessionOverride();
    }
  }, [error]);

  return (
    <Dialog open={true} onClose={onDialogClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>
        {t("appHeader.userMenu.impersonateUser.title")}
      </DialogTitle>
      <Formik
        initialValues={{
          usernameOverride: ""
        }}
        onSubmit={onSubmit}
      >
        <Form noValidate={true}>
          <DialogContent>
            <Stack spacing={2}>
              {error && <ApiErrorMessage apiError={error}/>}
              <TextInputField
                name="usernameOverride"
                label={t("appHeader.userMenu.impersonateUser.username")}
                required={true}
                variant="outlined"
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <LoadingButton
              isLoading={isLoading}
            >
              {t("appHeader.userMenu.impersonateUser.submit")}
            </LoadingButton>
            <Button
              onClick={onClose}
            >
              {t("common.cancel")}
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export { ImpersonateUserDialog };
