import * as React from "react";
import { ClientConfig } from "gen/clients/llts";
import { InitialEntryForm } from "./components/InitialEntryForm/InitialEntryForm";
import { ProjectEntryForm } from "./components/ProjectEntryForm/ProjectEntryForm";
import { CreateJobRequestPage } from "./components/CreateJobRequestPage/CreateJobRequestPage";

const UnsignedInUserPage: React.FC = () => {
  const [email, setEmail] = React.useState<string>();
  const [clientConfig, setClientConfig] = React.useState<ClientConfig>();

  const onInitialEntrySuccess = React.useCallback((providedEmail: string, loadedClientConfig?: ClientConfig) => {
    setEmail(providedEmail);
    setClientConfig(loadedClientConfig);
  }, []);

  if (email && clientConfig) {
    return <ProjectEntryForm email={email} clientConfig={clientConfig}/>;
  }
  if (email) {
/*
    return (
      <Container sx={{ mt: 5 }}>
        <Alert severity="error">{t("createProject.unsignedUser.validationMessages.unknownCompany")}</Alert>
      </Container>
    );
*/
    return <CreateJobRequestPage email={email}/>;
  }
  return <InitialEntryForm onSuccess={onInitialEntrySuccess}/>;
};

export { UnsignedInUserPage };
