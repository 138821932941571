import { PresignedPost } from "gen/clients/llts";

/**
 * Creates an S3 file key based on presigned post and file name.
 * @param presignedPost
 * @param fileName
 */
export function createFileKey(presignedPost: PresignedPost, fileName: string): string {
  // eslint-disable-next-line no-template-curly-in-string
  return presignedPost.fields.key.replaceAll("${filename}", fileName);
}