import * as React from "react";
import { useFormikContext } from "formik";
import { useEffect } from "react";

interface Props {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (newValue: any) => void;
}

const FormFieldObserver: React.FC<Props> = ({ fieldName, onChange }) => {
  const { values } = useFormikContext<Record<string, unknown>>();

  useEffect(() => {
    onChange(values[fieldName]);
  }, [values[fieldName]])
  return null;
};

export { FormFieldObserver };
