export const SUPPORTED_APPS = [
  "createProject",
  "xtrfDashboard",
  "invoices",
  "llsInterpretingService",
  "languageLineConnect",
  "websiteProxy",
  "connector",
  "machineTranslation",
  "quickTranslation",
  "consoleHelp",
  "reports"
];

export const USER_SESSION_OVERRIDE_SESSION_STORAGE_KEY = "llts.portal.user_session_override"