import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, CircularProgress, Link, Stack, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { FilesZipStatus, JobRequestsService } from "gen/clients/llts";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";

interface Props {
  jobRequestId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const DownloadTranslatedFilesDialog: React.FC<Props> = ({ jobRequestId, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const [firstQuery, setFirstQuery] = React.useState(true);
  const [shouldQuery, setShouldQuery] = React.useState(true);
  const { data: zipStatus, isLoading } = useQuery(["getFilesZipStatus", jobRequestId], {
    queryFn: () => JobRequestsService.getZipTranslatedFilesStatus({ jobRequestId }),
    refetchInterval: shouldQuery ? 1000 : false,
    enabled: shouldQuery,
    cacheTime: 0
  })
  const { mutate, error } = useMutation(JobRequestsService.zipJobRequestTranslatedFiles);

  React.useEffect( ()=> {
    if (zipStatus) {
      switch (zipStatus.status) {
        case FilesZipStatus.NOT_STARTED:
          setFirstQuery(false);
          mutate({ jobRequestId });
          break;
        case FilesZipStatus.FAILED:
          if (firstQuery) {
            setFirstQuery(false);
            mutate({ jobRequestId });
          } else {
            setShouldQuery(false);
          }
          break;
        case FilesZipStatus.COMPLETED:
          setShouldQuery(false);
          break;          
        default:
          break;
      }
    }
  }, [zipStatus]);

  const handleLinkClick = React.useCallback(() => {
    onSuccess();
  }, [onSuccess]);

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>{t("downloadTranslatedFilesDialog.title")}</DialogTitle>
      <DialogContent>
        <Stack sx={{ py: 5 }}>
          {!!error && <ApiErrorMessage apiError={error}/>}
          {(isLoading || zipStatus?.status && [FilesZipStatus.IN_PROGRESS, FilesZipStatus.NOT_STARTED].includes(zipStatus?.status)) &&
            <Stack direction="row" spacing={1}>
              <CircularProgress size={24} style={{ marginRight: 8 }}/>
              <Typography>{t("downloadTranslatedFilesDialog.inProgress")}</Typography>
            </Stack>
          }
          {zipStatus?.status === FilesZipStatus.FAILED && 
            <Typography color="error.main">{t("downloadTranslatedFilesDialog.failure")}</Typography>
          }
          {zipStatus?.status === FilesZipStatus.COMPLETED && 
            <Typography>
              {t("downloadTranslatedFilesDialog.completed")}
              <Link
                href={zipStatus.downloadUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleLinkClick}
                sx={{ ml: 1 }}>
                  {t("downloadTranslatedFilesDialog.clickHere")}
              </Link>
            </Typography>
          }
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("common.close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export { DownloadTranslatedFilesDialog };
