
import { FileLink } from "gen/clients/llts";
import { ContentInputMethod, FilesSectionFieldNames } from "../FilesSection/FilesSection";

export class CreateProjectFormValidator {
  public static validate(values: Record<string, unknown>, t: (str: string) => string): Record<string, string> {
    const errors: Record<string, string> = {};
    // Validate files
    if (values[FilesSectionFieldNames.inputMethod] === ContentInputMethod.FILES) {
      const files = values[FilesSectionFieldNames.files] as File[] | undefined;
      if (!files || files.length === 0) {
        errors[FilesSectionFieldNames.files] = t("createProject.createProjectForm.validationMessages.filesRequired");
      } else if (new Set(files.map(file => file.name)).size !== files.length) {
        errors[FilesSectionFieldNames.files] = t("createProject.createProjectForm.validationMessages.duplicateFiles");
      }
      // If reference files are present, validate them
      const referenceFiles = values[FilesSectionFieldNames.referenceFiles] as File[] | undefined;
      if ((values[FilesSectionFieldNames.includeReferenceFiles] as string[]).length > 0
        && referenceFiles && referenceFiles.length > 0) {
        if (new Set(referenceFiles.map(file => file.name)).size !== referenceFiles.length) {
          errors[FilesSectionFieldNames.referenceFiles] = t("createProject.createProjectForm.validationMessages.duplicateFiles");
        } else {
          const projectFileNames = (files || []).map(file => file.name);
          const referenceFileNames = referenceFiles.map(file => file.name);
          if (projectFileNames.filter(fileName => referenceFileNames.includes(fileName)).length > 0) {
            errors[FilesSectionFieldNames.referenceFiles] = t("createProject.createProjectForm.validationMessages.referenceFilesDuplicateProjectFiles");
          }
        }
      }
    }
    // Validate text input
    if (values[FilesSectionFieldNames.inputMethod] === ContentInputMethod.TEXT) {
      if (!(values[FilesSectionFieldNames.textInput] as string).trim()) {
        errors[FilesSectionFieldNames.textInput] = t("common.validation.required");
      }
    }

    if (values[FilesSectionFieldNames.inputMethod] === ContentInputMethod.FILELINKS) {
      const links = values[FilesSectionFieldNames.fileLinks] as FileLink[] | undefined;
      if (!links || links.length === 0) {
        errors[FilesSectionFieldNames.fileLinks] = t("createProject.createProjectForm.validationMessages.fileLinksRequired");
      }
    }

    return errors;
  }
}