import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { TableCell } from "@mui/material";
import { getTableHeads } from "../../resources";


export interface IHeaderRow {
  resourceKey: string
}

const HeaderRow: FC<IHeaderRow> = (props) => {
  const {resourceKey} = props;
  const {t} = useTranslation();

  const tableHeadList = getTableHeads(t, resourceKey);

  return (
    <>
      {
        tableHeadList.map((head) => <TableCell key={head}>{head}</TableCell>)
      }
    </>
  )
}

export default HeaderRow;