import * as React from "react";
import { ClientConfig } from "../../../../../gen/clients/llts";
import {
  UnknownUserCreateProjectForm
} from "../../../components/UnknownUserCreateProjectForm/UnknownUserCreateProjectForm";

interface Props {
  email: string;
  clientConfig: ClientConfig
}

const ProjectEntryForm: React.FC<Props> = ({ clientConfig, email }) => (
  <UnknownUserCreateProjectForm clientConfig={clientConfig} email={email}/>
);

export { ProjectEntryForm };
