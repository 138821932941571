import * as React from "react";
import { createFilterOptions, FormControl, TextField } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { Autocomplete, AutocompleteRenderInputParams } from "formik-mui";
import { useTranslation } from "react-i18next";
import { SelectOption } from "@mui/base";

interface Props {
  name: string;
  label: string;
  options: SelectOption<string>[];
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  helperText?: string;
  variant?: "outlined" | "filled" | "standard";
  size?: "small" | "medium";
}

const filterOptions = createFilterOptions({
  matchFrom: "start",
  trim: true
});

const SelectOneAutocompleteField: React.FC<Props> = (
  {
    name,
    label,
    options,
    required,
    disabled,
    isLoading,
    isError,
    helperText,
    variant = "standard",
    size
  }) => {
  const { t } = useTranslation();
  const { errors, touched, isSubmitting } = useFormikContext<Record<string, string>>();

  const validate = React.useCallback((value: string) =>
    required && !value ? t("common.validation.required") : undefined, [required]);

  const isOptionEqualToValue = React.useCallback(
    (option: SelectOption<string>, value: string | SelectOption<string>) =>
      option === value || option.value === value || option.value === (value as SelectOption<string>).value
    , []);

  return (
    <FormControl fullWidth>
      <Field
        component={Autocomplete}
        type="text"
        name={name}
        label={label}
        placeholder={isLoading ? "Loading..." : (isError && "Error" || "")}
        options={options}
        variant={variant}
        disabled={disabled || isLoading || isError || isSubmitting}
        required={required}
        validate={validate}
        isOptionEqualToValue={isOptionEqualToValue}
        filterOptions={filterOptions}
        renderInput={(params: AutocompleteRenderInputParams) =>
          <TextField
            {...params}
            // We have to manually set the corresponding fields on the input component
            name={name}
            error={(touched[name] && !!(errors[name] as string)) || isError}
            helperText={(touched[name] && (errors[name] as string)) || helperText}
            label={label}
            variant={variant}
            required={required}
            disabled={isSubmitting}
            size={size}
          />}
      />
    </FormControl>
  );
}

export { SelectOneAutocompleteField };
