import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IFrame } from "../../../interfaces/page";
import logging from "../../../config/logging";
import HomeCrumb from "../../../components/Breadcrumb/HomeCrumb";
import styles from "./IFrameViewer.module.scss";

const IFrameViewer: React.FC<IFrame> = (props) => {
  const { id, fileName } = props;
  const { t } = useTranslation();
  const name = t(`${id}.title`);

  const fileReference = `${process.env.PUBLIC_URL}/${fileName}`;

  useEffect(() => {
    logging.info(`Loading ${name}`);
  }, [name]);

  return (
    <div>
      <HomeCrumb />
      <div>
        <div className={styles.iframeContainer} >
          <iframe title={fileName} src={fileReference} />
        </div>
      </div>
    </div>
  );
};

export default IFrameViewer;
