/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Divider from "@mui/material/Divider";
import { DataTableColumn, DataTableRowAction } from "./types";

interface Props {
  columns: DataTableColumn[];
  data: any[];
  rowKey: (row: any) => string;
  isLoading?: boolean;
  error?: React.ReactNode;
  rowActions?: DataTableRowAction[] | ((row: any, index: number) => DataTableRowAction[]);
  onRowClick?: (row: any, rowIndex: number) => void;
  dense?: boolean
  noData?: React.ReactNode;
  onSelectRows?: ((rows: any[]) => void);
}

const DataTable: React.FC<Props> = (
  {
    data, columns, rowKey, isLoading, error,
    rowActions, onRowClick, dense, noData = "No data", onSelectRows
  }
) => {
  const [selectedRow, setSelectedRow] = React.useState();
  const [selectedRowIndex, setSelectedRowIndex] = React.useState<number>();
  const [rowActionMenuAnchorEl, setRowActionMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [checkedRows, setCheckedRows] = React.useState<any[]>([]);

  React.useEffect(() => {
    setCheckedRows([]);
    if (onSelectRows) {
      onSelectRows([]);
    }
  }, [data, onSelectRows]);

  const onTableRowClick = React.useCallback((row: any, index: number) => {
    setSelectedRow(row);
    setSelectedRowIndex(index);
    if (onRowClick) {
      onRowClick(row, index);
    }
  }, [setSelectedRow, onRowClick]);

  const onRowActionMenuClick = React.useCallback((event: React.MouseEvent<HTMLElement>, row: any, rowIndex) => {
    setRowActionMenuAnchorEl(event.currentTarget);
    setSelectedRow(row);
    setSelectedRowIndex(rowIndex);
    event.stopPropagation();
  }, [setRowActionMenuAnchorEl, setSelectedRow, setSelectedRowIndex]);

  const closeRowActionMenu = () => {
    setRowActionMenuAnchorEl(null);
  };

  const onRowActionClick = React.useCallback((rowAction: DataTableRowAction) => {
    if (rowAction.action) {
      rowAction.action(selectedRow);
    }
    closeRowActionMenu();
  }, [selectedRow]);

  const onSelectAllClick = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    let selectedRows;
    if (event.target.checked) {
      selectedRows = data;
    } else {
      selectedRows = [];
    }
    setCheckedRows(selectedRows);
    if (onSelectRows) {
      onSelectRows(selectedRows);
    }
  }, [data, onSelectRows]);

  const onChangeRowCheck = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, row: any) => {
    let selectedRows = [...checkedRows];
    if (event.target.checked) {
      selectedRows.push(row);
    } else {
      selectedRows = selectedRows.filter(r => r !== row);
    }
    setCheckedRows(selectedRows);
    if (onSelectRows) {
      onSelectRows(selectedRows);
    }
  }, [checkedRows, onSelectRows]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table size={dense ? "small" : "medium"}>
          <TableHead>
            <TableRow>
              {onSelectRows &&
                <TableCell>
                  <Checkbox
                    color="primary"
                    indeterminate={checkedRows.length > 0 && checkedRows.length < data.length}
                    checked={data.length > 0 && checkedRows.length === data.length}
                    onChange={onSelectAllClick}
                  />
                </TableCell>
              }
              {columns.map(column => (
                <TableCell key={column.id}>
                  {column.title}
                </TableCell>
              ))}
              {rowActions && <TableCell/>}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading &&
              <TableRow>
                <TableCell colSpan={rowActions ? columns.length + 1 : columns.length}>
                  <Box sx={{ display: "flex", justifyContent: "center", height: 45 }}>
                    <CircularProgress/>
                  </Box>
                </TableCell>
              </TableRow>
            }
            {error &&
              <TableRow>
                <TableCell colSpan={rowActions ? columns.length + 1 : columns.length}>
                  {error}
                </TableCell>
              </TableRow>
            }
            {!isLoading && !error && data.length === 0 &&
              <TableRow>
                <TableCell colSpan={rowActions ? columns.length + 1 : columns.length}>
                  {noData}
                </TableCell>
              </TableRow>
            }
            {!isLoading && !error && data.length > 0 && data.map((row, index) => (
              <TableRow
                key={rowKey(row)}
                onClick={() => onTableRowClick(row, index)}
                hover={!!onRowClick}
                sx={{ cursor: onRowClick ? "pointer" : undefined }}
              >
                {onSelectRows &&
                  <TableCell>
                    <Checkbox
                      color="primary"
                      checked={checkedRows.includes(row)}
                      onChange={(event) => onChangeRowCheck(event, row)}
                      onClick={e => e.stopPropagation()}
                    />
                  </TableCell>
                }
                {columns.map(column => (
                  <TableCell key={`cell-${rowKey(row)}-${column.id}`}>
                    {column.cell(row)}
                  </TableCell>
                ))}
                {rowActions && (
                  <TableCell key={`actionCell-${rowKey(row)}`} align="right">
                    <IconButton onClick={(event) => onRowActionMenuClick(event, row, index)}>
                      <MoreVertIcon/>
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {rowActions && selectedRow && selectedRowIndex !== undefined &&
        <Menu
          anchorEl={rowActionMenuAnchorEl}
          open={!!rowActionMenuAnchorEl}
          onClose={closeRowActionMenu}
          PaperProps={{
            style: {
              minWidth: 200
            }
          }}
        >
          {(typeof rowActions === "function" ? rowActions(selectedRow, selectedRowIndex) : rowActions)
            .map((rowAction) => rowAction.type === "DIVIDER" ?
              <Divider key={rowAction.title}/>
              : <MenuItem
                key={rowAction.title}
                onClick={() => onRowActionClick(rowAction)}
                sx={{ color: rowAction.color }}
                disabled={rowAction.disabled}
              >
                {rowAction.title}
              </MenuItem>
            )}
        </Menu>
      }
    </>
  );
};

export { DataTable };
