import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  JobRequestCollection,
  JobRequestOrigination,
  JobRequestsService,
  JobRequestSummary
} from "gen/clients/llts";
import { useQuery } from "react-query";
import { DataTable } from "components/DataTable/DataTable";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { formatDate } from "utils/dateUtils";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "usehooks-ts";
import { TokenBasedPaginator } from "components/TokenBasedPaginator/TokenBasedPaginator";
import { JobRequestState } from "../../components/JobRequestState";
import { useDialogState } from "../../../../../hooks/useDialogState/useDialogState";
import { RenameJobRequestDialog } from "../../MtJobRequestDetailsPage/RenameJobRequestDialog/RenameJobRequestDialog";
import { JobRequestUtils } from "../../../../../utils/jobRequestUtils";

interface Props {
  origination: JobRequestOrigination;
  refetchRef: React.MutableRefObject<(() => void) | undefined>;
}

const MtJobRequestList: React.FC<Props> = ({ origination, refetchRef }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useSessionStorage<number>("mtJobRequestList.rowsPerPage.1", 10);
  const [nextPageToken, setNextPageToken] = React.useState<string>();
  const [pageToken, setPageToken] = React.useState<string>();
  const [selectedJobRequest, setSelectedJobRequest] = React.useState<JobRequestSummary>();
  const [isRenameDialogOpen, openRenameDialog, closeRenameDialog] = useDialogState();
  const paginatorResetRef = React.useRef<() => void>(() => {
    window.console.log("placeholder function");
  });
  React.useEffect(() => {
    paginatorResetRef?.current();
  }, [rowsPerPage]);

  const { data, isLoading, error, refetch } = useQuery(["listJobRequests", rowsPerPage, pageToken], {
    queryFn: () => JobRequestsService.listJobRequests({
      origination,
      pageToken,
      pageSize: rowsPerPage
    }),
    onSuccess: (collection => {
      setNextPageToken(collection.nextPageToken);
    }),
    refetchInterval: (jrCollection: JobRequestCollection | undefined) =>
      jrCollection?.items
        .some(jr => JobRequestUtils.isInProgress(jr.status)) ? 10000 : false,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    cacheTime: 0
  });

  // eslint-disable-next-line no-param-reassign
  refetchRef.current = refetch;

  const rowKey = React.useCallback((jobRequest: JobRequestSummary) =>
    jobRequest.id, []);
  const onRowClick = React.useCallback((jobRequest: JobRequestSummary) => {
    history.push(`/machineTranslation/fileTranslation/${jobRequest.id}`);
  }, []);
  const nameCell = React.useCallback((jobRequest: JobRequestSummary) =>
    <Link
      onClick={(e) => {
        e.preventDefault();
        onRowClick(jobRequest);
      }}
      href={`/machineTranslation/fileTranslation/${jobRequest.id}`}>
      <Typography variant="body2">
        {jobRequest.name}
      </Typography>
    </Link>, []);
  const sourceLanguageCell = React.useCallback((jobRequest: JobRequestSummary) =>
    jobRequest.sourceLanguage?.name, []);
  const targetLanguagesCell = React.useCallback((jobRequest: JobRequestSummary) =>
    jobRequest.targetLanguages?.map(l => l.name)
      .sort((l1, l2) => l1.localeCompare(l2))
      .join(", "), []);
  const submittedOnCell = React.useCallback((jobRequest: JobRequestSummary) =>
    formatDate(jobRequest.createTime), []);
  const statusCell = React.useCallback((jobRequest: JobRequestSummary) =>
    <JobRequestState status={jobRequest.status} variant="body2"/>, []);

  const onRowsPerPageChange = React.useCallback((newRowsPerChange: number) => {
    setRowsPerPage(newRowsPerChange);
    setPageToken(undefined);
    setNextPageToken(undefined);
    paginatorResetRef?.current();
  }, []);

  const onRenameActionClick = React.useCallback((jobRequest: JobRequestSummary) => {
    setSelectedJobRequest(jobRequest);
    openRenameDialog();
  }, [])

  const onRenameSuccess = React.useCallback(() => {
    refetch();
    closeRenameDialog();
  }, [])

  return (
    <>
      <DataTable
        data={data?.items || []}
        columns={[
          {
            id: "name",
            title: <Typography fontWeight="bold">{t("machineTranslation.fileTranslation.requests.name")}</Typography>,
            cell: nameCell
          },
          {
            id: "sourceLanguage",
            title: <Typography fontWeight="bold">{t("machineTranslation.fileTranslation.requests.sourceLanguage")}</Typography>,
            cell: sourceLanguageCell
          },
          {
            id: "targetLanguage",
            title: <Typography fontWeight="bold">{t("machineTranslation.fileTranslation.requests.targetLanguages")}</Typography>,
            cell: targetLanguagesCell
          },
          {
            id: "submittedOn",
            title: <Typography fontWeight="bold">{t("machineTranslation.fileTranslation.requests.submittedOn")}</Typography>,
            cell: submittedOnCell
          },
          {
            id: "status",
            title: <Typography fontWeight="bold">{t("machineTranslation.fileTranslation.requests.status")}</Typography>,
            cell: statusCell
          },
        ]}
        rowKey={rowKey}
        error={!!error && <ApiErrorMessage apiError={error}/>}
        isLoading={isLoading}
        onRowClick={onRowClick}
        dense={true}
        rowActions={[
          {
            title: t("machineTranslation.fileTranslation.actions.viewDetails"),
            action: onRowClick,
          },
          {
            title: t("machineTranslation.fileTranslation.actions.rename"),
            action: onRenameActionClick,
          }
        ]}
      />
      <TokenBasedPaginator
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        onPageTokenChange={setPageToken}
        nextPageToken={nextPageToken}
        currentPageDataRows={data?.items.length}
        resetRef={paginatorResetRef}
      />

      {isRenameDialogOpen && selectedJobRequest &&
        <RenameJobRequestDialog
          jobRequestId={selectedJobRequest.id}
          oldName={selectedJobRequest.name}
          onClose={closeRenameDialog}
          onSuccess={onRenameSuccess}
        />
      }
    </>
  );
};

export { MtJobRequestList };
