import { useSelector } from "react-redux";
import { ApplicationState } from "../store/store";
import { UserSessionState } from "../store/userSession/types";

/**
 * Helper hook for getting current user session information.
 */
export const useUserSession = (): UserSessionState => {
  const { userSession } = useSelector((state: ApplicationState) => ({
    userSession: state.userSession
  }));
  return userSession;
}