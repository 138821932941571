import {
  UserSessionActions,
  UserSessionActionType,
  UserSessionState
} from "./types";

const initialState: UserSessionState = {
  username: undefined,
  roles: undefined,
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  initialPagePath: undefined,
  isFederatedSsoAccount: undefined
};

const userSessionReducer = (
  state = initialState,
  action: UserSessionActions
): UserSessionState => {
  switch (action.type) {
    case UserSessionActionType.SIGN_IN:
      return {
        ...state,
        username: action.username,
        email: action.email,
        roles: action.roles,
        isFederatedSsoAccount: action.isFederatedSsoAccount,
        firstName: action.firstName,
        lastName: action.lastName
      };
    case UserSessionActionType.SIGN_OUT:
      return {
        ...state,
        username: undefined,
        email: undefined,
        roles: undefined,
        isFederatedSsoAccount: undefined,
        firstName: undefined,
        lastName: undefined
      };
    case UserSessionActionType.SET_INITIAL_PAGE_PATH:
      return {
        ...state,
        initialPagePath: action.initialPagePath
      }
    default:
      return state;
  }
};

export default userSessionReducer;
