import * as React from "react";
import { CustomFieldDefinition, CustomFieldValue } from "gen/clients/llts";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { sanitizeFieldName } from "components/formikFields/formikUtils";
import { CustomField } from "components/formikFields/CustomField/CustomField";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { LoadingButton } from "components/LoadingButton/LoadingButton";

interface Props {
  logoUrl?: string;
  customFieldDefinitions: CustomFieldDefinition[];
  onValuesEntry: (values: CustomFieldValue[]) => void;
  onCancel: () => void;
  isLoading: boolean;
  error: unknown;
}

const CustomFieldValuesInput: React.FC<Props> = (
  { logoUrl, customFieldDefinitions, onValuesEntry, onCancel, isLoading, error }
) => {
  const { t } = useTranslation();

  const initialValues = React.useMemo(() => {
    const values: Record<string, string> = {};
    customFieldDefinitions
      .filter(f => f.visible)
      .forEach(f => {
        values[sanitizeFieldName(f.name)] = f.defaultValue || "";
      });
    return values;
  }, [customFieldDefinitions]);

  const onSubmit = React.useCallback((formValues: Record<string, string>) => {
    const customFieldValues: CustomFieldValue[] = [];
    Object.keys(formValues).forEach(fieldName => {
      const customFieldDefinition = customFieldDefinitions.find(f => sanitizeFieldName(f.name) === fieldName);
      if (!customFieldDefinition) {
        throw new Error(`Cannot find customFieldDefinition with name [${fieldName}]`);
      }
      customFieldValues.push({
        fieldName: customFieldDefinition.name,
        value: formValues[fieldName],
        mapping: customFieldDefinition.mapping
      });
    });
    onValuesEntry(customFieldValues);
  }, [customFieldDefinitions]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      {error && <SnackbarApiError error={error}/>}
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {logoUrl && <img src={logoUrl} alt={t("signUp.logo.alt")}/>}
        {!logoUrl &&
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon/>
          </Avatar>
        }
        <Typography component="h1" variant="h5">
          {t("signUp.signUp")}
        </Typography>
        <Typography component="h1" variant="h6" sx={{ my: 3 }}>
          {t("signUp.customFieldsInput.title")}
        </Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnMount={false}
      >
        <Form>
          {customFieldDefinitions
            .filter(fieldDefinition => fieldDefinition.visible)
            .map(fieldDefinition =>
              <Box key={fieldDefinition.name} sx={{ mb: 2 }}>
                <CustomField
                  customFieldDefinition={fieldDefinition}
                  variant="outlined"
                />
              </Box>
            )}

          <Grid container={true} spacing={1} sx={{ mt: 5, mb: 2 }}>
            <Grid item={true} xs={6}>
              <Button
                fullWidth
                variant="text"
                color="secondary"
                onClick={onCancel}
              >
                {t("common.cancel")}
              </Button>
            </Grid>
            <Grid item={true} xs={6}>
              <LoadingButton
                isLoading={isLoading}
                fullWidth
                variant="contained"
              >
                {t("signUp.signUpBtn")}
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </Container>
  );
};

export { CustomFieldValuesInput };
