import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

interface Props {
  title?: string;
  buttonText?: string;
  onClose?: () => void;
  children: React.ReactNode;
}

const AlertDialog: React.FC<Props> = ({ title, buttonText, onClose, children }) => {
  const { t } = useTranslation();
  const onDialogClose = React.useCallback((event, reason) => {
    if (reason !== "backdropClick") {
      if (onClose) {
        onClose();
      }
    }
  }, [onClose]);
  return (
    <Dialog open={true} onClose={onDialogClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        {onClose &&
          <Button onClick={onClose}>{buttonText || t("common.ok")}</Button>
        }
      </DialogActions>
    </Dialog>
  );
};

export { AlertDialog };
