import * as React from "react";
import { FormControl, MenuItem } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { useTranslation } from "react-i18next";
import { SelectOption } from "@mui/base";

interface Props {
  name: string;
  label: string;
  options: SelectOption<string | number>[];
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  helperText?: string;
  variant?: "outlined" | "filled" | "standard";
  size?: "small" | "medium"
}

const SelectOneField: React.FC<Props> = (
  {
    name,
    label,
    options,
    required,
    disabled,
    isLoading,
    isError,
    helperText,
    variant = "standard",
    size = "medium"
  }) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <Field
        component={TextField}
        type="text"
        name={name}
        label={label}
        helperText={helperText}
        placeholder={isLoading ? "Loading..." : (isError && "Error" || "")}
        select={true}
        variant={variant}
        disabled={disabled || isLoading || isError}
        required={required}
        validate={(value: string) => required && !value ? t("common.validation.required") : undefined}
        size={size}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
}

export { SelectOneField };
