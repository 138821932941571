import * as React from "react";
import { DropzoneArea } from "react-mui-dropzone";
import { Field, useFormikContext } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import { useTranslation } from "react-i18next";
import styles from "./DropzoneField.module.scss";

interface Props {
  name: string;
  maxFileSize?: number;
  dropzoneText?: string;
  filesLimit?: number;
  helperText?: string;
  acceptedFiles?: string[];
  required?: boolean;
}

const DropzoneField: React.FC<Props> = (
  { name, dropzoneText, maxFileSize, filesLimit, helperText, acceptedFiles, required }) => {
  const { t } = useTranslation();
  const {
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    touched,
    errors
  } = useFormikContext<Record<string, unknown>>();
  const [isInitialValidation, setIsInitialValidation] = React.useState(true);

  const validate = React.useCallback((value: File[]): string | undefined => {
    if (!isInitialValidation) {
      if (required && (value || []).length === 0) {
        return t("common.validation.required");
      }
      const uniqueFileNames = new Set<string>();
      value.forEach(f => uniqueFileNames.add(f.name));
      if (uniqueFileNames.size !== value.length) {
        return t("components.dropzoneField.duplicateFilesNotAllowed");
      }
    }
    setIsInitialValidation(false);
    return undefined;
  }, [isInitialValidation]);

  return (
    <div>
      <Field
        name={name}
        validate={validate}
        render={() => (
          <>
            <DropzoneArea
              onChange={(updatedFiles) => {
                if (!isInitialValidation) {
                  setFieldTouched(name);
                }
                setFieldValue(name, updatedFiles);
              }}
              filesLimit={filesLimit}
              maxFileSize={maxFileSize}
              dropzoneText={dropzoneText}
              showFileNames={true}
              showPreviews={false}
              showFileNamesInPreview={true}
              useChipsForPreview={true}
              dropzoneClass={styles.dropzone}
              dropzoneProps={{
                disabled: isSubmitting
              }}
              showAlerts={["error"]}
              acceptedFiles={acceptedFiles}
            />
            <FormHelperText error={touched[name] && !!errors[name]}>
              {(touched[name] && errors[name]) || helperText}
            </FormHelperText>
          </>
        )}
      />
    </div>
  );
};

export { DropzoneField };
