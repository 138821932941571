import * as React from "react";
import { Box, Container, Typography } from "@mui/material";

interface Props {
  title?: string;
  children: React.ReactNode;
  paddingTop?: number;
  paddingBottom?: number;
  overTitleContent?: React.ReactNode;
}

const Hero: React.FC<Props> = (
  {
    title,
    children,
    overTitleContent,
    paddingTop,
    paddingBottom
  }) => (
  <Box sx={{ pb: paddingBottom || 8, pt: paddingTop || 8, mb: 5, backgroundColor: "#f7f7f7" }}>
    <Container maxWidth="md">
      {overTitleContent}
      {title &&
        <Typography variant="h3" gutterBottom={true}>{title}</Typography>
      }
      {children}
    </Container>
  </Box>

);

export { Hero };
