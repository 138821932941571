// noinspection HtmlUnknownTarget

import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import "./welcome.scss";


// noinspection HtmlUnknownTarget
const PreLogin: FC = () => {

  useEffect(() => {
    const loginButtonSpan = document.getElementById("sign-in-button-span");

    if(loginButtonSpan) {
      loginButtonSpan.setAttribute("hidden", "");
    }
  })

  const toggleHeaderLogin = () => {
    const loginButtonSpan = document.getElementById("sign-in-button-span");
    if(loginButtonSpan) {
      loginButtonSpan.hidden = !loginButtonSpan.hidden;
    }
  }

  const { t } = useTranslation();
  const history = useHistory();
  const testClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const requestSubject = event.currentTarget.id;
    if(requestSubject === "sign-in") {
      const loginButton = document.getElementById("sign-in-button");
      if(loginButton) {
        loginButton.click();
      }
    } else if(requestSubject === "request-quote") {
      toggleHeaderLogin();
      history.push("/createProject");
    }
  }
  const requestAQuoteButton = {backgroundColor: "#fa9b0e", hoverBackgroundColor: "#aa6b0b"};
  const signInButton = {backgroundColor: "#0057a0", hoverBackgroundColor: "#004077"};
  return(
    <>
      <section id="welcome">
        <div className="left">
          <div>
            <h1 className="fs4 fw2 text-darkblue">{t("preLogin.getStarted")}</h1>
              <div className="button-row">
                <Button onClick={testClick} id="sign-in" {...signInButton} >
                  {t("preLogin.signIn")}
                </Button>
                <Button onClick={testClick} id="request-quote" {...requestAQuoteButton} data-testid="request-quote" >
                  {t("preLogin.requestQuote")}
                </Button>
              </div>
            <h3 className="text-blue voffset3 fw8 fs19">{t("preLogin.challenges")}</h3>
          </div>
        </div>
        <img src="/images/welcome-mobile.jpg" alt="" className="hide-desktop voffset5" style={{width:"100vw"}}/>
        <div/>
      </section>
    </>
  )
}

export default PreLogin