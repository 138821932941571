import * as React from "react";
import { ApiError, PresignedPost } from "../../gen/clients/llts";
import { S3FileUploader } from "../../utils/S3FileUploader";

interface Props {
  files?: File[];
  presignedPost?: PresignedPost;
}

interface Response {
  invoke: (props: Props) => void;
  isUploading: boolean;
  isSuccess?: boolean;
  error?: ApiError;
  uploadProgress: number;
  reset: () => void;
}

export const useS3FileUpload = (): Response => {
  const [isUploading, setIsUploading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState<boolean>();
  const [error, setError] = React.useState<ApiError>();
  const [uploadedBytes, setUploadedBytes] = React.useState(0);
  const [totalBytesToUpload, setTotalBytesToUpload] = React.useState(0);

  const uploadProgress = React.useMemo(() => {
    if (totalBytesToUpload === 0) {
      return 0;
    }
    return 100 * uploadedBytes / totalBytesToUpload;
  }, [totalBytesToUpload, uploadedBytes]);

  const onUploadProgress = React.useCallback((delta: number) => {
    setUploadedBytes((oldValue) => oldValue + delta)
  }, [setUploadedBytes]);

  const invoke = React.useCallback(({ files, presignedPost }: Props) => {
    if (!files || !files.length || !presignedPost) {
      return;
    }
    const upload = async () => {
      setTotalBytesToUpload(files.map(f => f.size).reduce((p, c) => p + c, 0));
      setUploadedBytes(0);
      setIsUploading(true);
      try {
        setIsUploading(true);
        setIsSuccess(undefined);
        setError(undefined);
        await S3FileUploader.upload(files, presignedPost, onUploadProgress);
        setIsUploading(false)
        setIsSuccess(true);
      } catch (e) {
        setError(e as ApiError);
      }
    };
    upload();
  }, []);

  const reset = React.useCallback(() => {
    setIsSuccess(undefined);
    setIsUploading(false);
    setUploadedBytes(0);
    setTotalBytesToUpload(0);
  }, []);

  return {
    invoke,
    isUploading,
    isSuccess,
    error,
    uploadProgress,
    reset
  }
}