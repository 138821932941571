import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IPage } from "../../../interfaces/page";
import logging from "../../../config/logging";
import {getPageData} from "../../../config/utilities";
import HomeCrumb from "../../../components/Breadcrumb/HomeCrumb";

const ServiceInfoPage: FC<IPage> = (props) => {
  const { id } = props;
  const { t } = useTranslation();
  const name = t(`${id}.title`);

  useEffect(() => {
    logging.info(`Loading ${name}`);
  }, [name]);

  const {imgRef, title, body} = getPageData(id, t);

  return (
    <div>
      <HomeCrumb />
      <div style={{ padding: "1em" }}>
        <p>
          This is the <strong>{title}</strong> page with the id of {id}!
        </p>
        <div style={{ display: "flex" }}>
          <img src={imgRef} style={{ width: "100px", height: "100px" }} alt={id} />
          <p style={{ marginLeft: "20px", width: "75%" }}>{body}</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceInfoPage;
