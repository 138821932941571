import * as React from "react";
import MuiInfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

interface Props {
  tooltip: string
}

const InfoIcon: React.FC<Props> = ({ tooltip }) => (
  <Tooltip
    title={<Typography variant="body2">{tooltip}</Typography>}
    placement="top"
    arrow={true}
  >
    <MuiInfoIcon color="info"/>
  </Tooltip>
);

export { InfoIcon };
