import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IAppCardPage } from "../../interfaces/appCard";
import AppCardContainer from "../../components/AppCardContainer/AppCardContainer";
import "./cardPage.css";

const AppCardPage: FC<IAppCardPage> = (props) => {
  const {appCardNames, name = "Unknown"} = props;
  const {t} = useTranslation();


  return(
    <>
      <section id="appcard-page" className="voffset3">
        <div className="container-lls">
          <div className="row-lls ac">
            <h4 className="fw6 fs18 mb0" style={{paddingBottom: "1.5rem"}}>{t("appCardPage.welcomeBack", {name})}</h4>
            <h4 className="fw4 fs8" style={{ margin: "0"}}>{t("appCardPage.yourTools")}</h4>
          </div>
          <div className="row-lls" id="tools">
            {appCardNames.length > 0 ?
              <AppCardContainer appCardNames={appCardNames}/>
              : t("appCardPage.noApplications")
            }
          </div>
        </div>
      </section>
    </>
  );

}

export default AppCardPage;