import React, { useMemo } from "react";
import { useFormikContext } from "formik";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import { useTranslation } from 'react-i18next';
import { DataTable } from "components/DataTable/DataTable";
import { useDialogState } from "hooks/useDialogState/useDialogState";
import { DataTableRowAction } from "components/DataTable/types";
import { AddFileLinkDialog } from "./AddFileLinkDialog";
import { FileLink } from "../../../../gen/clients/llts";

interface FileLinksFieldProps {
  name: string;
}

const FileLinksField: React.FC<FileLinksFieldProps> = ({ name }) => {

  const { t } = useTranslation();
  const { values, setFieldValue, errors, setFieldError, touched } = useFormikContext<Record<string, unknown>>();

  const [isAddFileLinkDialogOpen, openAddFileLinkDialog, closeAddFileLinkDialog] = useDialogState();

  const fileLinkList = useMemo(() => (
    (values[name] || []) as FileLink[]
  ), [values[name]]);

  const updateFileLinkList = React.useCallback((fileLinks: FileLink[]) => {
    setFieldValue(name, fileLinks);
  }, [name, setFieldValue]);

  const rowKey = React.useCallback((fileLink: FileLink) => fileLink.url, []);
  const urlCell = React.useCallback((fileLink: FileLink) => fileLink.url, []);
  const nameCell = React.useCallback((fileLink: FileLink) => fileLink.name, []);

  const rowActions = React.useCallback((fileLink: FileLink): DataTableRowAction[] => [
    {
      title: t("createProject.createProjectForm.fileLinksField.delete"),
      color: "error.light",
      action: () => {
        const indexOfSelectedFilelink = fileLinkList.findIndex(link => link.url === fileLink.url);
        if (indexOfSelectedFilelink !== -1) {
          fileLinkList.splice(indexOfSelectedFilelink, 1);
          updateFileLinkList(fileLinkList);
        }
      }
    }
  ], [fileLinkList, updateFileLinkList])

  const onAddFileLink = React.useCallback((fileLink: FileLink) => {
    closeAddFileLinkDialog();
    updateFileLinkList([...fileLinkList, fileLink]);
    setFieldError(name, undefined);
  }, [closeAddFileLinkDialog, fileLinkList]);

  return (
    <div>
      <Button
        type="button"
        variant="contained"
        onClick={() => openAddFileLinkDialog()}
        sx={{ mb: 1 }}
      >
        {t("createProject.createProjectForm.fileLinksField.addMoreLinks")}
      </Button>
      {fileLinkList.length > 0 &&
        <DataTable
          columns={[
            {
              id: "url",
              title: t("createProject.createProjectForm.fileLinksField.urlColumn"),
              cell: urlCell
            },
            {
              id: "name",
              title: t("createProject.createProjectForm.fileLinksField.nameColumn"),
              cell: nameCell
            }
          ]}
          data={fileLinkList}
          rowKey={rowKey}
          rowActions={rowActions}
          dense={true}
        />
      }

      {touched[name] && errors[name] &&
        <FormHelperText error={true}>{errors[name]}</FormHelperText>
      }

      {isAddFileLinkDialogOpen && <AddFileLinkDialog
        uniqueLinks={fileLinkList}
        onAdd={onAddFileLink}
        onClose={closeAddFileLinkDialog}
      />}
    </div>
  );
};

export default FileLinksField;
