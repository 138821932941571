/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from "@mui/material";

interface Props {
  breadcrumbs: BreadcrumbItem[];
}

export interface BreadcrumbItem {
  label: string;
  path?: string;
  active?: boolean;
}

const Breadcrumbs: React.FC<Props> = ({ breadcrumbs }) => {
  const history = useHistory();
  const onBreadcrumbItemClick = React.useCallback((path: string) => {
    history.push(path);
  }, [history]);

  return (
    <MuiBreadcrumbs>
      {breadcrumbs.map(breadcrumbItem => (
        <span key={breadcrumbItem.label}>
          {breadcrumbItem.active ?
            <Typography
              color="text.primary">
              {breadcrumbItem.label}
            </Typography>
            :
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
              underline="hover"
              component="button"
              color="inherit"
              onClick={breadcrumbItem.path ? () => onBreadcrumbItemClick(breadcrumbItem.path!) : undefined}
            >
              {breadcrumbItem.label}
            </Link>
          }
        </span>
      ))}
    </MuiBreadcrumbs>
  );
};

export { Breadcrumbs };
