import { CSVExporter } from "./CSVExporter";
import { JSONExporter } from "./JSONExporter";
import { ReportExportType, Exporter } from "./ExportTypes";

// Builder function
export const createExporter = (exportType : ReportExportType) : Exporter => {
  switch (exportType) {
    case ReportExportType.CSV:
      return new CSVExporter();
    case ReportExportType.JSON:
      return new JSONExporter();
    // Add more export types as needed
    default:
      throw new Error(`Unsupported export type: ${exportType}`);
  }
};
