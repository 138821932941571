import * as React from "react";
import { useParams } from "react-router-dom";
import { Alert, Box, Container } from "@mui/material";
import { useQuery } from "react-query";
import {
  ApiError,
  ClientConfigsService
} from "gen/clients/llts";
import { LoadingPage } from "components/LoadingPage/LoadingPage";
import { ErrorPage } from "components/ErrorPage/ErrorPage";
import { useTranslation } from "react-i18next";
import { UnknownUserCreateProjectForm } from "../components/UnknownUserCreateProjectForm/UnknownUserCreateProjectForm";

interface RouterParams {
  clientId: string;
  token?: string;
}

const CreateProjectFromExternalLinkPage: React.FC = () => {
  const { t } = useTranslation();
  const { clientId: encodedClientId, token } = useParams<RouterParams>();

  const clientId = React.useMemo(() => encodedClientId && atob(decodeURIComponent(encodedClientId))
    , [encodedClientId]);

  const {
    data: clientConfig,
    isLoading: isClientConfigLoading,
    error: clientConfigError
  } = useQuery(["getClientConfig", clientId], {
    queryFn: () => ClientConfigsService.getClientConfig({ clientId })
  })

  if (isClientConfigLoading) {
    return <LoadingPage/>;
  }
  if (clientConfigError) {
    return <ErrorPage apiError={clientConfigError as ApiError}/>
  }
  if (!token || (clientConfig && clientConfig.createProjectToken !== token)) {
    return (
      <Box margin={4}>
        <h3>{t("createProject.fromExternalLink.invalidUrl.title")}</h3>
        <p>{t("createProject.fromExternalLink.invalidUrl.message")}</p>
      </Box>
    );
  }
  if(clientConfig?.status !== "ACTIVE"){
    return (
      <Container maxWidth="md">
        <Alert severity="error" sx={{ mt: 3 }}>
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: t("createProject.fromExternalLink.inactiveClient") }}/>
        </Alert>
      </Container>
    );
  }

  return (
    <>
      {clientConfig &&
        <UnknownUserCreateProjectForm clientConfig={clientConfig}/>
      }
    </>
  );
};

export { CreateProjectFromExternalLinkPage };
