import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { grey } from '@mui/material/colors';
import { useTranslation } from "react-i18next";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import { ReportMetadataSummary, ReportsService } from "../../gen/clients/llts";
import { SnackbarApiError } from "../../components/SnackbarApiError/SnackbarApiError";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { LoadingPage } from "../../components/LoadingPage/LoadingPage";

interface ReportSection {
  title: string;
  reports: ReportMetadataSummary[];
}

const ReportsPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { data: reports, isLoading, error } = useQuery("listReports", {
    queryFn: ReportsService.listReports
  })

  const breadcrumbs = React.useMemo(() => (
    [
      {
        label: t("crumbText.home"),
        path: "/",
        active: false
      },
      {
        label: t("reports.title"),
        path: "/reports",
        active: true
      }
    ]
  ), []);

  const reportSections: ReportSection[] = React.useMemo(() => [
    {
      title: t("reports.projectReports"),
      reports: reports?.filter(r => r.type === ReportMetadataSummary.type.PROJECT) || []
    },
    {
      title: t("reports.quoteReports"),
      reports: reports?.filter(r => r.type === ReportMetadataSummary.type.QUOTE) || []
    },
    {
      title: t("reports.taskReports"),
      reports: reports?.filter(r => r.type === ReportMetadataSummary.type.TASK) || []
    },
    {
      title: t("reports.clientContactReports"),
      reports: reports?.filter(r => r.type === ReportMetadataSummary.type.CLIENT_CONTACT) || []
    },
  ], [reports]);

  const onViewReportClick = React.useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    history.push(`/reports/${event.currentTarget.id}`)
  }, []);

  if (isLoading) {
    return <LoadingPage/>;
  }

  return (
    <Container maxWidth="xl" sx={{ my: 4 }}>
      {error &&
        <SnackbarApiError error={error}/>
      }
      <Breadcrumbs breadcrumbs={breadcrumbs}/>
      <Stack spacing={6} sx={{ mt: 1, p: 4, backgroundColor: grey[50] }}>
        {reportSections.filter(r => r.reports.length > 0).map(reportSection => (
          <Box key={reportSection.title}>
            <Typography variant="h5" gutterBottom={true}>{reportSection.title}</Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }} disablePadding={true}>
              {reportSection.reports.map((report, index) =>
                <React.Fragment key={report.id}>
                  {index > 0 && <Divider component="li"/>}
                  <ListItem disablePadding>
                    <ListItemButton
                      id={report.id}
                      onClick={onViewReportClick}
                      sx={{ minHeight: 80 }}
                    >
                      <ListItemIcon>
                        <Avatar>
                          <img src="/images/services/reports.svg" style={{ width: 24 }} alt="Report"/>
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText
                        primary={report.name}
                        primaryTypographyProps={{ variant: "button" }}
                        secondary={report.description}
                      />
                    </ListItemButton>
                  </ListItem>
                </React.Fragment>
              )}
            </List>
          </Box>
        ))
        }
      </Stack>
    </Container>
  );
};

export { ReportsPage };
