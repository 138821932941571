import * as React from "react";
import { Box } from "@mui/material";

interface Props {
  children: React.ReactNode;
}

const DrawerBody: React.FC<Props> = ({ children }) => (
  <Box sx={{ px: 3, py: 1 }}>
    {children}
  </Box>
);

export { DrawerBody };
