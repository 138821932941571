import React, { FC, MouseEvent } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store/store";
import styles from "./AppCardContainer.module.scss";
import AppCard from "../AppCard/AppCard";
import { IAppCardContainer } from "../../interfaces/appCard";
import { LLS_HOME_URL, REACT_APP_MY_LLS_PORTAL } from "../../config/urls";

const AppCardContainer: FC<IAppCardContainer> = (props) => {
  const {appCardNames} = props;
  const history = useHistory();

  const { username } = useSelector((state: ApplicationState) => ({
    roles: state.userSession.roles || [],
    username: state.userSession.username
  }));

  const { data: xtrfClientPortalAuthData } = useQuery("getXtrfAuthUrl", {
    enabled: !!username, // TODO: roles.includes(LingoNetRole.CLIENT) || roles.includes(LingoNetRole.CLIENT_EMPLOYEE),
    queryFn: async () => {
      const resp = await window.fetch(process.env.REACT_APP_XTRF_CLIENT_PORTAL_AUTH_URL || "");
      return (await resp.json() as Promise<{ authUrl: string }>);
    }
  })

  const onClick = (e: MouseEvent) => {
    const {id} = e.currentTarget;
    switch (id) {
      case "invoices" :
        window.open(
          REACT_APP_MY_LLS_PORTAL
        )
        break;
      case "llsInterpretingService" :
        window.open(
          `${LLS_HOME_URL}/interpreting`
        )
        break;
      case "xtrfDashboard":
        if(xtrfClientPortalAuthData) {
          window.open(
              xtrfClientPortalAuthData.authUrl
          )
        }
        break;
      default:
        history.push(`/${id}`)
    }
  }

  const appCards = appCardNames.map((name) => (
    <AppCard rootResource={name} key={name} onClick={onClick}/>
  ))

  return (
    <div className={styles.toolsWrapper}>
      {appCards}
    </div>
  );
}

export default AppCardContainer;