function makeTwoDigitNumber(value: number): string {
  return value < 10 ? `0${value}` : `${value}`;
}

/**
 * Generates project name based on contact's email or last name
 * @param contactEmail
 */
export function createProjectName(contactEmail: string | undefined): string {
  const now = new Date();
  let projectNamePrefix = "";
  if (contactEmail) {
    projectNamePrefix = contactEmail.substring(0, contactEmail.indexOf("@"));
  }
  if (!projectNamePrefix) {
    projectNamePrefix = "project";
  }
  return `${projectNamePrefix}-${now.getFullYear()}${
    makeTwoDigitNumber(now.getMonth() + 1)}${
    makeTwoDigitNumber(now.getDate())}-${
    makeTwoDigitNumber(now.getHours())}${
    makeTwoDigitNumber(now.getMinutes())}${
    makeTwoDigitNumber(now.getSeconds())}`
}
