import * as React from "react";
import { useMutation } from "react-query";
import { FormikProps } from "formik/dist/types";
import { AxiosError } from "axios";
import {
  CreateProjectRequest, CreateQuoteRequest,
  FileCategory,
  LogMessage,
  PresignedPost,
  ProjectsService,
  QuotesService,
  UtilsService
} from "../../../gen/clients/llts";
import { S3FileUploader } from "../../../utils/S3FileUploader";
import { ContentInputMethod } from "../components/CreateProjectForm/CreateProjectForm";
import { FilesSectionFieldNames } from "../components/FilesSection/FilesSection";
import { ProjectInfoFieldName } from "../components/ProjectInfoSection/ProjectInfoSection";
import { sanitizeFileName } from "../../../utils/stringUtils";

interface UseCreateProjectProps {
  formRef: React.RefObject<FormikProps<Record<string, unknown>>>;
  onSuccess: () => void;
}

/**
 * Hook that encapsulates project/quote submission flow.
 * @param files files to upload
 * @param onSuccess on success callback
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCreateProject = ({ onSuccess, formRef }: UseCreateProjectProps) => {
  const [uploadedBytes, setUploadedBytes] = React.useState(0)

  const {
    mutate: addProjectFiles,
    isLoading: isAddProjectFilesSubmitting
  } = useMutation(ProjectsService.addProjectFiles);

  const {
    mutate: addQuoteFiles,
    isLoading: isAddQuoteFilesSubmitting
  } = useMutation(QuotesService.addQuoteFiles)

  const getFiles = React.useCallback(() => {
    if (!formRef.current) {
      return [];
    }
    const values = formRef.current?.values || {};
    switch (values[FilesSectionFieldNames.inputMethod]) {
      case ContentInputMethod.FILES:
        return (values[FilesSectionFieldNames.files] || []) as File[];
      case ContentInputMethod.TEXT: {
        const fileName = `${sanitizeFileName(values[ProjectInfoFieldName.projectName] as string)}.txt`;
        return [new File([values[FilesSectionFieldNames.textInput] as string || ""], fileName, { type: "text/plain" })];
      }
      default:
        window.console.error("Unable to collect form files");
        return [];
    }
  }, [
    formRef.current?.values[FilesSectionFieldNames.inputMethod],
    formRef.current?.values[FilesSectionFieldNames.files],
    formRef.current?.values[FilesSectionFieldNames.textInput],
    formRef.current?.values[ProjectInfoFieldName.projectName]
  ]);

  const getReferenceFiles = React.useCallback(() => {
    if (!formRef.current) {
      return [];
    }
    const values = formRef.current?.values || {};
    return (values[FilesSectionFieldNames.referenceFiles] as File[] | undefined) || [];
  }, [formRef.current?.values[FilesSectionFieldNames.referenceFiles]]);

  const bytesToUpload = React.useMemo(() =>
    getFiles().map(f => f.size).reduce((p, c) => p + c, 0) +
    getReferenceFiles().map(f => f.size).reduce((p, c) => p + c, 0),
    [getFiles, getReferenceFiles])

  const submissionProgress = React.useMemo(() => {
    if(bytesToUpload === 0){
      return 0;
    }
    const result = 100 * uploadedBytes / bytesToUpload
    return result
  }, [bytesToUpload, uploadedBytes])

  const onUploadProgress = React.useCallback((delta: number) => {
    setUploadedBytes((oldValue) => oldValue + delta)
  }, [setUploadedBytes])

  async function uploadProjectFiles(
    isQuote: boolean,
    files: File[],
    presignedPost: PresignedPost,
    projectId: string,
    filesCategory: FileCategory
  ) {
    window.console.log(`Starting to upload ${filesCategory} files`);
    UtilsService.logUiMessage({
      requestBody:
        {
          level: LogMessage.level.INFO,
          message: `Starting to upload files with category ${filesCategory} to S3`,
          details: { files, projectId, filesCategory }
        }
    });
    await S3FileUploader.upload(files, presignedPost, onUploadProgress);
    window.console.log(`Registering ${filesCategory} files in XTRF`);
    UtilsService.logUiMessage({
      requestBody:
        {
          level: LogMessage.level.INFO,
          message: `Files with category ${filesCategory} successfully uploaded to S3`,
          details: { files, projectId, filesCategory }
        }
    });
    if (isQuote) {
      await addQuoteFiles({
        quoteId: projectId,
        requestBody: {
          fileNames: files.map(f => f.name),
          category: filesCategory
        }
      });
    } else {
      await addProjectFiles({
        projectId,
        requestBody: {
          fileNames: files.map(f => f.name),
          category: filesCategory
        }
      });
    }
  }

  const {
    mutate: createProject,
    isLoading: isCreateProjectSubmitting,
    error: createProjectError
  } = useMutation(ProjectsService.createProject, {
    onSuccess: async data => {
      try {
        const files = getFiles();
        await uploadProjectFiles(false, files, data.presignedPost, data.projectId, FileCategory.SOURCE_DOCUMENT);
        const referenceFiles = getReferenceFiles();
        if (referenceFiles.length > 0) {
          await uploadProjectFiles(false, referenceFiles, data.presignedPost, data.projectId, FileCategory.REFERENCE);
        }
        onSuccess();
        UtilsService.logUiMessage({
          requestBody:
            {
              level: LogMessage.level.INFO,
              message: `All files for a project are successfully uploaded to S3`,
              details: { files, projectId: data.projectId }
            }
        });
      } catch (e) {
        UtilsService.logUiMessage({
          requestBody: {
            level: LogMessage.level.ERROR,
            message: "Error during file uploading in project creation",
            details: {
              projectId: data.projectId,
              error: {
                e,
                message: (e as Error)?.message,
                code: (e as AxiosError)?.code,
                data: (e as AxiosError)?.response?.data,
                stack: (e as Error)?.stack,
                name: (e as Error)?.name
              }
            }
          }
        });
        throw e;
      }
    }
  });

  const {
    mutate: createQuote,
    isLoading: isCreateQuoteSubmitting,
    error: createQuoteError
  } = useMutation(QuotesService.createQuote, {
    onSuccess: async data => {
      try {
        const files = getFiles();
        await uploadProjectFiles(true, files, data.presignedPost, data.quoteId, FileCategory.SOURCE_DOCUMENT);
        const referenceFiles = getReferenceFiles();
        if (referenceFiles.length > 0) {
          await uploadProjectFiles(true, referenceFiles, data.presignedPost, data.quoteId, FileCategory.REFERENCE);
        }
        onSuccess();
        UtilsService.logUiMessage({
          requestBody:
            {
              level: LogMessage.level.INFO,
              message: `All files for a quote are successfully uploaded to S3`,
              details: { files, quoteId: data.quoteId }
            }
        });
      } catch (e) {
        UtilsService.logUiMessage({
          requestBody: {
            level: LogMessage.level.ERROR,
            message: "Error during file uploading in quote creation",
            details: {
              quoteId: data.quoteId,
              error: { e, message: (e as Error)?.message, stack: (e as Error)?.stack, name: (e as Error)?.name }
            }
          }
        });
        throw e;
      }
    }
  });

  const startCreateProject = React.useCallback((request: { requestBody?: CreateProjectRequest }) => {
    setUploadedBytes(0);
    createProject(request);
  }, [setUploadedBytes, createProject]);

  const startCreateQuote = React.useCallback((request: { requestBody?: CreateQuoteRequest }) => {
    setUploadedBytes(0);
    createQuote(request);
  }, [setUploadedBytes, createQuote]);

  return {
    createProject: startCreateProject,
    createQuote: startCreateQuote,
    createProjectError,
    createQuoteError,
    isInProgress: isAddProjectFilesSubmitting || isCreateProjectSubmitting || isAddQuoteFilesSubmitting || isCreateQuoteSubmitting,
    submissionProgress
  }
}