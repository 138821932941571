import * as React from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { useDialogState } from "../../hooks/useDialogState/useDialogState";

interface Props {
  message: string;
  severity?: AlertColor;
  horizontal?: "left" | "center" | "right";
  vertical?: "top" | "bottom";
  autoHideDuration?: number | undefined;
}

const SnackbarAlert: React.FC<Props> = (
  { message, severity = "info", horizontal = "center", vertical = "top", autoHideDuration = 15000 }) => {
  const [isOpen, , close] = useDialogState(true);

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={close}
      anchorOrigin={{ horizontal, vertical }}
      disableWindowBlurListener={true}
      ClickAwayListenerProps={{
        onClickAway: () => {
          // do nothing
        }
      }}
    >
      <Alert onClose={close} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export { SnackbarAlert };
