import * as React from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { JobRequestStatus } from "gen/clients/llts";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { JobRequestUtils } from "../../../../utils/jobRequestUtils";

interface LocalProps {
  status: JobRequestStatus;
}

type Props = LocalProps & TypographyProps;

const StatusComp: React.FC<{ status: string, inProgress?: boolean } & TypographyProps> =
  ({ status, color, inProgress, ...others }) => (
    <Stack direction="row" spacing={1} alignItems="center">
      {inProgress &&
        <CircularProgress size={10}/>
      }
      <Typography color={color} {...others}>{status}</Typography>
    </Stack>
  )

const JobRequestState: React.FC<Props> = ({ status, ...others }) => {
  switch (status) {
    case JobRequestStatus.ANALYZING:
      return <StatusComp color="info.main" status="Analyzing"
                         inProgress={JobRequestUtils.isInProgress(status)} {...others}/>;
    case JobRequestStatus.TRANSLATING:
      return <StatusComp color="info.main" status="Translating"
                         inProgress={JobRequestUtils.isInProgress(status)} {...others}/>;
    case JobRequestStatus.COMPLETED:
      return <StatusComp color="success.main" status="Completed"
                         inProgress={JobRequestUtils.isInProgress(status)} {...others}/>;
    case JobRequestStatus.CREATED:
      return <StatusComp color="info.main" status="Processing"
                         inProgress={JobRequestUtils.isInProgress(status)} {...others}/>;
    case JobRequestStatus.ARCHIVED:
      return <StatusComp status="Archived" inProgress={JobRequestUtils.isInProgress(status)} {...others}/>;
    case JobRequestStatus.READY_FOR_CLIENT_REVIEW:
      return <StatusComp status="Ready for client review"
                         inProgress={JobRequestUtils.isInProgress(status)} {...others}/>;
    case JobRequestStatus.READY_FOR_INTERNAL_REVIEW:
      return <StatusComp color="info.main" status="Processing"
                         inProgress={JobRequestUtils.isInProgress(status)} {...others}/>;
    case JobRequestStatus.SCREENING:
      return <StatusComp color="info.main" status="Processing"
                         inProgress={JobRequestUtils.isInProgress(status)} {...others}/>;
    case JobRequestStatus.REJECTED_BY_SCREENING:
      return <StatusComp color="info.main" status="Processing"
                         inProgress={JobRequestUtils.isInProgress(status)} {...others}/>;
    case JobRequestStatus.SUBMITTING_TO_XTRF:
      return <StatusComp color="info.main" inProgress={JobRequestUtils.isInProgress(status)}
                         status="Submitting to Human Expert" {...others}/>;
    case JobRequestStatus.SUBMITTED_TO_XTRF:
      return <StatusComp color="success.main" status="Submitted to Human Expert"
                         inProgress={JobRequestUtils.isInProgress(status)} {...others}/>;
    case JobRequestStatus.CANCELLED:
      return <StatusComp color="error.main" status="Cancelled"
                         inProgress={JobRequestUtils.isInProgress(status)} {...others}/>;
    case JobRequestStatus.CANCELLING:
      return <StatusComp color="error.main" status="Cancelling"
                         inProgress={JobRequestUtils.isInProgress(status)} {...others}/>;
    case JobRequestStatus.FAILED:
      return <StatusComp color="error.main" status="Failed"
                         inProgress={JobRequestUtils.isInProgress(status)} {...others}/>;
    default:
      return <StatusComp {...others} status={status} inProgress={JobRequestUtils.isInProgress(status)}/>;
  }
};

export { JobRequestState };