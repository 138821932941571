import { ReportColumn, ReportMetadata, ReportRowCollection } from "gen/clients/llts";
import { Exporter } from "./ExportTypes";

export class JSONExporter implements Exporter {

  exportData(report: ReportMetadata, reportData: ReportRowCollection) : { blob: Blob; filename: string; } {
    const jsonData = this.convertToJSON(report.columns, reportData);
    const jsonBlob = new Blob([jsonData], { type: 'application/json' });
    const filename = `${report.name}-${new Date().getTime()}.json`;

    return { blob: jsonBlob, filename };
  }

  /* eslint-disable-next-line  class-methods-use-this */
  convertToJSON(columns: ReportColumn[], reportData: ReportRowCollection): string {
    return JSON.stringify(reportData);
  }
}
