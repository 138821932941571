import { useQuery } from "react-query";
import { Country, UtilsService } from "gen/clients/llts";
import * as React from "react";
import { SelectOption } from "@mui/base";

interface Response {
  countries?: Country[];
  countryOptions: SelectOption<number>[];
  isCountriesLoading: boolean;
  countriesError: unknown;
}

export const useCountryOptions = (): Response => {
  const { data: countries, isLoading: isCountriesLoading, error: countriesError } = useQuery("listCountries", {
    queryFn: UtilsService.listCountries
  })
  const countryOptions = React.useMemo(() =>
      countries?.sort((l1, l2) => l1.name.localeCompare(l2.name))
      .map(l => ({ label: l.name, value: l.id })) || []
    , [countries]);

  return {
    countries,
    countryOptions,
    isCountriesLoading,
    countriesError
  }
}