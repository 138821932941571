import React, { FC, ReactElement } from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { emphasize, styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";

export interface IBreadCrumb {
  crumbText: string;
  crumbIcon: ReactElement | undefined;
  click: React.MouseEventHandler<HTMLDivElement>;
}

export const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
    '&:hover': {
      cursor: "pointer"
    }

  };
});

const BreadCrumb: FC<IBreadCrumb> = (props) => {
  const {crumbText, crumbIcon, click} = props;

  return (
    <div style={{width: 20}}>
      <Breadcrumbs aria-label="breadcrumb"
        onClick={click}
      >
        <StyledBreadcrumb
          label={crumbText}
          icon={crumbIcon}
        />
      </Breadcrumbs>
    </div>
  )
}

export default BreadCrumb;