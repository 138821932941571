import * as React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Field } from "formik";
import { RadioGroup } from "formik-mui";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { SelectOption } from "@mui/base";

interface Props {
  name: string;
  options: SelectOption<string>[];
  label?: React.ReactNode;
  row?: boolean;
  required?: boolean;
}

const RadioGroupField: React.FC<Props> = (
  { name, label, options, row, required }) => (
  <FormControl>
    <FormLabel>{label}</FormLabel>
    <Field
      component={RadioGroup}
      row={row}
      name={name}
      required={required}
    >
      {options.map(option => (
        <FormControlLabel
          value={option.value}
          control={<Radio/>}
          label={(option.label as string | undefined) || ""}
        />
      ))}
    </Field>
  </FormControl>
);

export { RadioGroupField };
