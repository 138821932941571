import * as React from "react";
import { useTranslation } from "react-i18next";
import { parseApiErrorMessage } from "../ApiErrorMessage/ApiErrorMessage";
import { SnackbarAlert } from "../SnackbarAlert/SnackbarAlert";

interface Props {
  error: unknown;
}

const SnackbarApiError: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation();

  return (
    <SnackbarAlert message={parseApiErrorMessage(error, t("common.unexpectedError"))} severity="error"/>
  );
};

export { SnackbarApiError };
