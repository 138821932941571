import { TFunction } from "i18next";

// eslint-disable-next-line import/prefer-default-export
export const getPageData = (
  idName: string,
  t: TFunction
): { imgRef: string; title: string; body: string; description: string } => {
  const imgKey = `${idName}.img`;
  const imgRef = `${t(imgKey)}`;
  const titleKey = `${idName}.title`;
  const title = `${t(titleKey)}`;
  const bodyKey = `${idName}.long`;
  const body = `${t(bodyKey)}`;
  const descriptionKey = `${idName}.short`;
  const description = `${t(descriptionKey)}`;
  return { imgRef, title, body, description };
};
