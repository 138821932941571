import * as React from "react";
import { useTranslation } from "react-i18next";

const PASSWORD_MIN_LENGTH = 8;
const MIN_DIGITS = 1;
const MIN_UPPER_CASE_LETTERS = 1;
const MIN_LOWER_CASE_LETTERS = 1;

interface UsePasswordValidatorResponse {
  validatePassword: (password: string) => string | undefined;
}

export const usePasswordValidator = (): UsePasswordValidatorResponse => {
  const { t } = useTranslation();

  const validatePassword = React.useCallback((password: string): string | undefined => {
    if(!password){
      return t("common.validation.required");
    }
    if (password.length < PASSWORD_MIN_LENGTH) {
      return t("common.validation.password.minLength", { minLength: PASSWORD_MIN_LENGTH });
    }
    if (password.replace(/[^0-9]/g, "").length < MIN_DIGITS) {
      return t("common.validation.password.minDigits");
    }
    if (password.replace(/[^A-Z]/g, "").length < MIN_UPPER_CASE_LETTERS) {
      return t("common.validation.password.minUpperCaseLetters");
    }
    if (password.replace(/[^a-z]/g, "").length < MIN_LOWER_CASE_LETTERS) {
      return t("common.validation.password.minLowerCaseLetters");
    }
    return undefined;
  }, [t]);

  return {
    validatePassword
  }
}