/* eslint-disable react/no-danger */
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { LLS_HOME_URL, TELEPERFORMANCE_PRIVACY_URL } from "../../config/urls";
import "./Footer.scss";

const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div
        dangerouslySetInnerHTML={{
          __html: t("footer.copyright", {
            teleperformancePrivacy: TELEPERFORMANCE_PRIVACY_URL,
            llsDotCom: LLS_HOME_URL,
            fullYear: new Date().getFullYear()
          })
        }}
      />
    </footer>
  );
};

export default Footer;