import * as React from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik/dist/types";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { ScrollToFieldError } from "components/ScrollToFieldError/ScrollToFieldError";
import {
  CreateJobRequestPayload,
  FileLink,
  JobRequestFile,
  JobRequestFileCategory,
  JobRequestFileLink,
  JobRequestLanguage,
  JobRequestsService,
  JobRequestType,
  PaymentPreferences,
  UtilsService
} from "gen/clients/llts";
import { useMutation } from "react-query";
import { useCountryOptions } from "hooks/useCountryOptions/useCountryOptions";
import { useProvinceOptions } from "hooks/useProvinceOptions/useProvinceOptions";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import Stack from "@mui/material/Stack";
import { EmailVerificationDialog } from "components/EmailVerificationDialog/EmailVerificationDialog";
import { createFileKey } from "utils/s3Utils";
import { CreateJobRequestForm } from "../../../components/CreateJobRequestForm/CreateJobRequestForm";
import { sanitizeFileName, validateEmailFormat } from "../../../../../utils/stringUtils";
import { CreateProjectFormValidator } from "../../../components/CreateProjectForm/CreateProjectFormValidator";
import { FormHeader } from "../../../components/FormHeader/FormHeader";
import { PaymentField, PaymentFormValues, PaymentSection } from "../../../components/PaymentSection/PaymentSection";
import { useS3FileUpload } from "../../../../../hooks/useS3FileUpload/useS3FileUpload";
import { RequestSubmitted } from "../../../components/RequestSubmitted/RequestSubmitted";
import { SubmitType } from "../../../components/CreateProjectForm/CreateProjectForm";
import {
  UserContactSectionField,
  UserContactSectionFormValues
} from "../../../components/UserContactSection/UserContactSection";
import { ProjectInfoFieldName, ProjectInfoFormValues } from "../../../components/ProjectInfoSection/ProjectInfoSection";
import {
  ContentInputMethod,
  FilesSectionFieldNames,
  FilesSectionFormValues
} from "../../../components/FilesSection/FilesSection";
import { useDialogState } from "../../../../../hooks/useDialogState/useDialogState";
import paymentType = PaymentPreferences.paymentType;
import { AlertDialog } from "../../../../../components/AlertDialog/AlertDialog";
import { LinearProgressWithLabel } from "../../../../../components/LinearProgressWithLabel/LinearProgressWithLabel";

interface Props {
  email: string;
}

const DEFAULT_COUNTRY_CODE = "227";  // USA

type Values = UserContactSectionFormValues & FilesSectionFormValues & ProjectInfoFormValues & PaymentFormValues;

const CreateJobRequestPage: React.FC<Props> = ({ email }) => {
  const { t } = useTranslation();
  const formikRef = React.useRef<FormikProps<Values>>(null);
  const [requestType, setRequestType] = React.useState<JobRequestType>();
  const [verificationCode, setVerificationCode] = React.useState<string>();
  const [
    isEmailVerificationDialogOpen,
    openEmailVerificationDialog,
    closeEmailVerificationDialog
  ] = useDialogState();

  const {
    mutate: createPresignedPost,
    data: presignedPost,
    isLoading: isCreatingPresignedPost,
    error: createPresignedPostError
  } =
    useMutation(UtilsService.createTempFileUploadPresignedPost);

  const {
    invoke: uploadFiles,
    isUploading: areFilesUploading,
    isSuccess: areFilesUploaded,
    error: filesUploadError,
    uploadProgress
  } =
    useS3FileUpload();

  const {
    mutate: createJobRequest,
    isLoading: isCreatingJobRequest,
    error: createJobRequestError,
    isSuccess: jobRequestCreated
  } = useMutation(JobRequestsService.createJobRequest);

  const error = createPresignedPostError || filesUploadError || createJobRequestError;
  const formValues = formikRef.current?.values;

  const { countryOptions } = useCountryOptions();
  const { provinceOptions: contactProvinceOptions } =
    useProvinceOptions(formValues && formValues[UserContactSectionField.country] ? +formValues[UserContactSectionField.country] : undefined);
  const { provinceOptions: paymentProvinceOptions } =
    useProvinceOptions(formValues && formValues[PaymentField.country] ? +formValues[PaymentField.country] : undefined);

  // --- Upload files when presigned post is created ---
  React.useEffect(() => {
    if (!presignedPost || !formValues) {
      return;
    }

    const contentInputMethod = formValues[FilesSectionFieldNames.inputMethod];
    if (contentInputMethod === ContentInputMethod.FILELINKS)
      return;

    const files: File[] = [];
    switch (contentInputMethod) {
      case ContentInputMethod.FILES: {
        const documentFiles: File[] | undefined = formValues[FilesSectionFieldNames.files];
        const referenceFiles: File[] | undefined = formValues[FilesSectionFieldNames.referenceFiles];
        files.push(...(documentFiles || []), ...(referenceFiles || []));
        break;
      }
      case ContentInputMethod.TEXT: {
        const fileName = `${sanitizeFileName(formValues[ProjectInfoFieldName.projectName] as string)}.txt`;
        files.push(
          new File([formValues[FilesSectionFieldNames.textInput] as string || ""], fileName, { type: "text/plain" })
        );
        break;
      }
      default:
        throw new Error(`Invalid input type ${contentInputMethod}`);
    }
    uploadFiles({ files, presignedPost });
  }, [presignedPost]);

  // Submit a request to create Job Request once files are uploaded
  React.useEffect(() => {

    if (!formValues || !presignedPost || !verificationCode) {
      return;
    }

    const files: JobRequestFile[] = [];
    const contentInputMethod = formValues[FilesSectionFieldNames.inputMethod];
    if (contentInputMethod !== ContentInputMethod.FILELINKS) {
      if (!areFilesUploaded)
        return;

      switch (contentInputMethod) {
        case ContentInputMethod.FILES: {
          const documentFiles: JobRequestFile[] | undefined =
            formValues[FilesSectionFieldNames.files]?.map(file =>
              ({
                fileName: file.name,
                category: JobRequestFileCategory.SOURCE_DOCUMENT,
                fileKey: createFileKey(presignedPost, file.name)
              }));
          const referenceFiles: JobRequestFile[] | undefined =
            formValues[FilesSectionFieldNames.referenceFiles]?.map(file =>
              ({
                fileName: file.name,
                category: JobRequestFileCategory.REFERENCE,
                fileKey: createFileKey(presignedPost, file.name)
              }));
          files.push(...(documentFiles || []), ...(referenceFiles || []));
          break;
        }
        case ContentInputMethod.TEXT: {
          const fileName = `${sanitizeFileName(formValues[ProjectInfoFieldName.projectName] as string)}.txt`;
          files.push({
            fileName,
            category: JobRequestFileCategory.SOURCE_DOCUMENT,
            fileKey: createFileKey(presignedPost, fileName)
          });
          break;
        }
        default:
          throw new Error(`Invalid input type ${contentInputMethod}`);
      }
    }

    const fileLinks : JobRequestFileLink[] | undefined = 
      formValues[FilesSectionFieldNames.inputMethod] === ContentInputMethod.FILELINKS ?
      (formValues[FilesSectionFieldNames.fileLinks] as FileLink[]).map(link => ({
        url: link.url,
        name: link.name,
        category: JobRequestFileCategory.SOURCE_DOCUMENT
      })) : undefined;

    const sourceLanguageSelectedOption = formValues[ProjectInfoFieldName.sourceLanguage];
    if(!sourceLanguageSelectedOption){
      throw new Error("Source language is not selected");
    }
    const sourceLanguage: JobRequestLanguage = {
      id: +sourceLanguageSelectedOption.value,
      name: (sourceLanguageSelectedOption.label) as string
    };
    const targetLanguages: JobRequestLanguage[] = formValues[ProjectInfoFieldName.targetLanguages]
      .map(l => ({ id: +l.value, name: (l.label as string) }));
    
    const contactCountry = countryOptions.find(c => c.value === +formValues[UserContactSectionField.country]);
    const paymentCountry = countryOptions.find(c => c.value === +formValues[PaymentField.country]);
    const contactProvince = contactProvinceOptions.find(p => p.value === +formValues[UserContactSectionField.province]);
    const paymentProvince = paymentProvinceOptions.find(p => p.value === +formValues[PaymentField.state]);
    
    const requestBody: CreateJobRequestPayload = {
      requestType,
      sourceLanguage,
      targetLanguages,
      files,
      fileLinks,
      requestorEmail: formValues[UserContactSectionField.email],
      requestorFirstName: formValues[UserContactSectionField.firstName],
      requestorLastName: formValues[UserContactSectionField.lastName],
      notes: formValues[ProjectInfoFieldName.notes],
      contactAddress: {
        address1: formValues[UserContactSectionField.address1] || undefined,
        address2: formValues[UserContactSectionField.address2] || undefined,
        city: formValues[UserContactSectionField.city] || undefined,
        zipCode: formValues[UserContactSectionField.zip] || undefined,
        phoneNumber: formValues[UserContactSectionField.phone] || undefined,
        email: formValues[UserContactSectionField.email] || undefined,
        province: formValues[UserContactSectionField.province] ? {
          id: +formValues[UserContactSectionField.province] || 0,
          name: contactProvince?.label as string
        } : undefined,
        country: formValues[UserContactSectionField.country] ? {
          id: +formValues[UserContactSectionField.country] || 0,
          name: contactCountry?.label as string
        } : undefined
      },
      paymentPreferences: {
        paymentType: formValues[PaymentField.paymentOption],
        billingAccount: formValues[PaymentField.paymentOption] === paymentType.BILL_TO_ACCOUNT ? {
          clientIdNumber: formValues[PaymentField.clientIdNumber],
          personalCode: formValues[PaymentField.personalCode] || undefined
        } : undefined,
        billingContact: formValues[PaymentField.paymentOption] === paymentType.INVOICE_DIRECTLY ? {
          address1: formValues[PaymentField.address1] || undefined,
          address2: formValues[PaymentField.address2] || undefined,
          city: formValues[PaymentField.city] || undefined,
          province: formValues[PaymentField.state] ? {
            id: +formValues[PaymentField.state],
            name: paymentProvince?.label as string
          } : undefined,
          country: formValues[PaymentField.country] ? {
            id: +formValues[PaymentField.country],
            name: paymentCountry?.label as string
          } : undefined,
          zipCode: formValues[PaymentField.zip] || undefined,
          email: formValues[PaymentField.email] || undefined,
          phoneNumber: formValues[PaymentField.phone] || undefined
        } : undefined
      },
      emailVerificationCode: verificationCode
    };
    createJobRequest({
      requestBody
    });
  }, [areFilesUploaded, requestType, presignedPost, verificationCode]);

  React.useEffect(() => {
    if (isCreatingPresignedPost || areFilesUploading || isCreatingJobRequest) {
      formikRef.current?.setSubmitting(true);
    }
  }, [isCreatingPresignedPost, areFilesUploading, isCreatingJobRequest]);

  React.useEffect(() => {
    if (createPresignedPostError || filesUploadError || createJobRequestError || jobRequestCreated)
      formikRef.current?.setSubmitting(false);
  }, [createPresignedPostError, filesUploadError, createJobRequestError, jobRequestCreated]);

  const onSubmit = React.useCallback(() => {
    openEmailVerificationDialog();
  }, [openEmailVerificationDialog]);

  const onEmailVerificationCancel = React.useCallback(() => {
    formikRef.current?.setSubmitting(false);
    closeEmailVerificationDialog();
  }, [closeEmailVerificationDialog]);

  const onEmailVerificationSuccess = React.useCallback((validatedverificationCode: string) => {
    setVerificationCode(validatedverificationCode);
    closeEmailVerificationDialog();
    createPresignedPost();
  }, []);

  const validate = React.useCallback((values: Values) => {
    const errors: Partial<Values> = {};
    // Validate email
    const emailValue = values.email as string;
    if (!emailValue) {
      errors.email = t("common.validation.required");
    } else if (!validateEmailFormat(emailValue)) {
      errors.email = t("createProject.unknownUser.validationMessages.emailFormat");
    }
    const documentInputErrors = CreateProjectFormValidator.validate(values as unknown as Record<string, unknown>, t);
    return { ...errors, ...documentInputErrors };
  }, []);


  const initialValues = React.useMemo(() => {
    const standardFieldValues = {
      [UserContactSectionField.email]: email || "",
      [UserContactSectionField.firstName]: "",
      [UserContactSectionField.lastName]: "",
      [UserContactSectionField.address1]: "",
      [UserContactSectionField.address2]: "",
      [UserContactSectionField.city]: "",
      [UserContactSectionField.province]: "",
      [UserContactSectionField.zip]: "",
      [UserContactSectionField.country]: DEFAULT_COUNTRY_CODE,
      [UserContactSectionField.phone]: "",
      [ProjectInfoFieldName.sourceLanguage]: null,
      [ProjectInfoFieldName.targetLanguages]: [],
      [ProjectInfoFieldName.notes]: "",
      [ProjectInfoFieldName.projectName]: "",
      [ProjectInfoFieldName.xtrfServiceId]: "",
      [FilesSectionFieldNames.inputMethod]: ContentInputMethod.FILES,
      [FilesSectionFieldNames.files]: [],
      [FilesSectionFieldNames.fileLinks]: [],
      [FilesSectionFieldNames.textInput]: "",
      [FilesSectionFieldNames.includeReferenceFiles]: [],
      [FilesSectionFieldNames.referenceFiles]: [],
      [PaymentField.paymentOption]: PaymentPreferences.paymentType.CREDIT_CARD,
      [PaymentField.clientIdNumber]: "",
      [PaymentField.personalCode]: "",
      [PaymentField.sameAddressAsContact]: [],
      [PaymentField.address1]: "",
      [PaymentField.address2]: "",
      [PaymentField.city]: "",
      [PaymentField.state]: "",
      [PaymentField.country]: DEFAULT_COUNTRY_CODE,
      [PaymentField.zip]: "",
      [PaymentField.email]: "",
      [PaymentField.phone]: "",
    };
    return {
      ...standardFieldValues,
    }
  }, []);

  if (jobRequestCreated && requestType) {
    const submitType: SubmitType = (requestType === JobRequestType.TRANSLATION_REQUEST) ?
      SubmitType.PROJECT : SubmitType.ESTIMATE;
    return <RequestSubmitted submitType={submitType}/>;
  }

  return (
    <>
      <CssBaseline/>
      <FormHeader
        title={t("createProject.unknownUser.header.title")}
      >
        <p>
          {t("createProject.unknownUser.header.content1")}
        </p>
        {/* eslint-disable-next-line react/no-danger */}
        <p dangerouslySetInnerHTML={{ __html: t("createProject.unknownUser.header.content2") }}/>
      </FormHeader>
      <Container maxWidth="md">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validate={validate}
              validateOnMount={false}
              innerRef={formikRef}
            >
              {({ isSubmitting }) => (
                <Form noValidate={true} autoComplete="off" autoCorrect="off">
                  <ScrollToFieldError/>
                  <CreateJobRequestForm/>
                  <PaymentSection/>
                  <Box marginY={5}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mr: 1 }}
                      loading={isSubmitting && requestType === JobRequestType.QUOTE_REQUEST}
                      onClick={() => {
                        setRequestType(JobRequestType.QUOTE_REQUEST);
                      }}
                    >
                      {t("createProject.createProjectForm.sendForEstimation")}
                    </LoadingButton>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="large"
                      loading={isSubmitting && requestType === JobRequestType.TRANSLATION_REQUEST}
                      onClick={() => {
                        setRequestType(JobRequestType.TRANSLATION_REQUEST);
                      }}
                    >
                      {t("createProject.createProjectForm.sendTotTranslation")}
                    </LoadingButton>
                  </Box>
                  {isSubmitting &&
                    <AlertDialog title={t("createProject.createProjectForm.submittingAlert.title")}>
                      <Stack spacing={1}>
                        <Box>
                          {t("createProject.createProjectForm.submittingAlert.text")}
                        </Box>
                        <Box sx={{ width: "100%" }}>
                          <LinearProgressWithLabel value={uploadProgress} />
                        </Box>
                      </Stack>
                    </AlertDialog>
                  }
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
        {error &&
          <SnackbarApiError error={error}/>
        }
        {isEmailVerificationDialogOpen &&
          <EmailVerificationDialog
            email={formikRef?.current?.values[UserContactSectionField.email] as string || ""}
            onSuccess={onEmailVerificationSuccess}
            onClose={onEmailVerificationCancel}
          />
        }        
      </Container>
    </>
  );
};

export { CreateJobRequestPage };
