import * as React from "react";
import { Form, Formik } from "formik";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { DrawerActions } from "components/DrawerActions/DrawerActions";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { sanitizeFieldName } from "components/formikFields/formikUtils";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import {
  CustomFieldDefinition,
  CustomFieldValue,
  JobRequestFile,
  JobRequestFileCategory,
  JobRequestsService,
  JobRequestType,
  UtilsService
} from "gen/clients/llts";
import { useMutation, useQuery } from "react-query";
import { Grid } from "@mui/material";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { CustomField } from "components/formikFields/CustomField/CustomField";
import { useTranslation } from "react-i18next";
import { SelectOption } from "@mui/base";
import {
  SelectOneAutocompleteField
} from "components/formikFields/SelectOneAutocompleteField/SelectOneAutocompleteField";
import {
  SelectManyAutocompleteField
} from "components/formikFields/SelectManyAutocompleteField/SelectManyAutocompleteField";
import { SELECT_OPTION_COMPARATOR } from "utils/stringUtils";
import { ProjectInfoFieldName } from "../../../../CreateProjectPage/components/ProjectInfoSection/ProjectInfoSection";
import { createProjectName } from "../../../../CreateProjectPage/utils/projectUtils";
import { LoadingPage } from "../../../../../components/LoadingPage/LoadingPage";
import { SelectOneField } from "../../../../../components/formikFields/SelectOneField/SelectOneField";

interface Props {
  jobRequestId: string;
  requestName?: string;
  email: string;
  sourceLanguageId: string;
  targetLanguageIds: string[];
  projectCustomFieldDefinitions?: CustomFieldDefinition[];
  files: JobRequestFile[];
  onClose: () => void;
  onSuccess: () => void;
}

enum FieldNames {
  projectName = "projectName",
  sourceLanguage = "sourceLanguage",
  targetLanguages = "targetLanguages",
  reason = "reason",
  notes = "notes"
}

interface FormValues {
  [FieldNames.projectName]: string,
  [ProjectInfoFieldName.sourceLanguage]: SelectOption<string> | null;
  [ProjectInfoFieldName.targetLanguages]: SelectOption<string>[];
  [FieldNames.reason]: string,
  [FieldNames.notes]: string
}

enum RequestReason {
  FORMATTING = "Clean up formatting",
  TRANSLATION = "Need human translation"
}

const RequestHumanTranslationPanelForm: React.FC<Props> = (
  {
    jobRequestId,
    requestName,
    email,
    sourceLanguageId,
    targetLanguageIds,
    projectCustomFieldDefinitions,
    files,
    onClose,
    onSuccess
  }) => {
  const { t } = useTranslation();
  const { mutateAsync, isLoading, error } = useMutation(JobRequestsService.submitToXtrf);

  const { data: languages, isLoading: areLanguagesLoading, error: languagesError } = useQuery("listXtrfLltsLanguages", {
    queryFn: () => UtilsService.listLltsLanguages({ xtrfIdExists: true })
  });
  const languageOptions = React.useMemo(() =>
      languages?.map(l => ({ label: l.name, value: `${l.id}` }))
        .sort(SELECT_OPTION_COMPARATOR) || []
    , [languages]);

  const humanTranslationReasonOptions = React.useMemo(() => ([
    {
      label: t("machineTranslation.requestHumanTranslation.reasons.CLEAN_UP_FORMATTING"),
      value: RequestReason.FORMATTING
    },
    {
      label: t("machineTranslation.requestHumanTranslation.reasons.NEED_HUMAN_TRANSLATION"),
      value: RequestReason.TRANSLATION
    }
  ]), []);

  const initialValues = React.useMemo(() => {
    const selectedSourceLanguageOption = languageOptions.find(o => o.value === sourceLanguageId);
    const selectedTargetLanguageOptions = languageOptions.filter(o => targetLanguageIds.includes(o.value));
    const standardFieldValues = {
      [FieldNames.projectName]: requestName || createProjectName(email),
      [FieldNames.sourceLanguage]: selectedSourceLanguageOption || null,
      [FieldNames.targetLanguages]: selectedTargetLanguageOptions || [],
      [FieldNames.notes]: "",
      [FieldNames.reason]: ""
    };
    const customFieldValues: Record<string, string | string[]> = {};
    projectCustomFieldDefinitions?.forEach((customFieldDefinition: CustomFieldDefinition) => {
      customFieldValues[sanitizeFieldName(customFieldDefinition.name)] = customFieldDefinition.defaultValue || "";
    })
    return {
      ...standardFieldValues,
      ...customFieldValues
    }
  }, [projectCustomFieldDefinitions, email, sourceLanguageId, targetLanguageIds, languageOptions]);


  const onSubmit = React.useCallback(async (values: FormValues) => {
    const customFieldValues: CustomFieldValue[] | undefined = projectCustomFieldDefinitions?.map(fieldDefinition => ({
      fieldName: fieldDefinition.name,
      mapping: fieldDefinition.mapping,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value: values[sanitizeFieldName(fieldDefinition.name)]
    }));
    const notes = `Source: Translation Portal Self Service MT File app escalation to human expert\nReason for request: ${values[FieldNames.reason]}\n\n${values[FieldNames.notes]}`;
    const selectedTargetLanguageIds = values[FieldNames.targetLanguages].map(o => o.value);
    // Remove translated files that are not in selected target languages
    let filesToSubmit: JobRequestFile[] = files
      .filter(f => f.category !== JobRequestFileCategory.TRANSLATED_DOCUMENT ||
        (f.languageId && selectedTargetLanguageIds.includes(f.languageId))
      );
    // Per LLTS-1816, assign to translated files category OTHER if selected reason is translation
    if (values[FieldNames.reason] === RequestReason.TRANSLATION) {
      filesToSubmit = filesToSubmit.map(f => f.category === JobRequestFileCategory.TRANSLATED_DOCUMENT ?
        {...f, category: JobRequestFileCategory.OTHER} : f
      );
    }
    await mutateAsync({
      jobRequestId,
      requestBody: {
        projectName: values[FieldNames.projectName] || "",
        requestType: JobRequestType.QUOTE_REQUEST,
        sourceLanguageId: values[FieldNames.sourceLanguage]?.value,
        targetLanguageIds: selectedTargetLanguageIds,
        notes,
        customFields: customFieldValues,
        files: filesToSubmit
      }
    }, { onSuccess });
  }, [projectCustomFieldDefinitions]);

  if (areLanguagesLoading) {
    return <LoadingPage/>;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Form noValidate={true} autoComplete="off">
        {error && <SnackbarApiError error={error}/>}
        <Typography variant="body1" mb={3}>
          {t("machineTranslation.requestHumanTranslation.provideAdditionalInformation")}
        </Typography>
        <Grid container={true} spacing={2}>
          {/* Project name */}
          <Grid item xs={12}>
            <TextInputField
              name={FieldNames.projectName}
              label={t("machineTranslation.requestHumanTranslation.projectName.label")}
              helperText={t("machineTranslation.requestHumanTranslation.projectName.helperText")}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectOneAutocompleteField
              name={FieldNames.sourceLanguage}
              label={t("createProject.createProjectForm.sourceLanguage.label")}
              helperText={t("createProject.createProjectForm.sourceLanguage.helperText")}
              options={languageOptions}
              isLoading={areLanguagesLoading}
              isError={!!languagesError}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectManyAutocompleteField
              name={FieldNames.targetLanguages}
              label={t("createProject.createProjectForm.targetLanguage.label")}
              helperText={t("createProject.createProjectForm.targetLanguage.helperText")}
              options={languageOptions}
              isLoading={areLanguagesLoading}
              isError={!!languagesError}
              required={true}
            />
          </Grid>          
          {/* Project custom fields */}
          {projectCustomFieldDefinitions && projectCustomFieldDefinitions.map(customFieldDefinition => (
            <React.Fragment key={customFieldDefinition.name}>
              {customFieldDefinition.visible !== false &&
                <Grid item xs={12}>
                  <CustomField customFieldDefinition={customFieldDefinition}/>
                </Grid>
              }
            </React.Fragment>
          ))}
          <Grid item={true} xs={12}>
            <SelectOneField
              name={FieldNames.reason}
              label={t("machineTranslation.requestHumanTranslation.reason")}
              required={true}
              options={humanTranslationReasonOptions}
            />
          </Grid>
          {/* Notes */}
          <Grid item xs={12}>
            <TextInputField
              name={ProjectInfoFieldName.notes}
              label={t("machineTranslation.requestHumanTranslation.notes")}
              multiline={true}
              rows={5}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <DrawerActions>
          <LoadingButton isLoading={isLoading}>
            {t("machineTranslation.requestHumanTranslation.submit")}
          </LoadingButton>
          <Button onClick={onClose} sx={{ ml: 1 }}>
            {t("machineTranslation.requestHumanTranslation.close")}
          </Button>
        </DrawerActions>
      </Form>
    </Formik>
  );
};

export { RequestHumanTranslationPanelForm };
